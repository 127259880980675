@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.carousel {
  width: 90%;
  margin: auto;
  margin-bottom: 100px;
}
.carousel h2:nth-child(1) {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 40px;
}
.card {
  width: 100%;
  position: relative;
  cursor: pointer;
  aspect-ratio: 5/7;
  /* margin: 0px 5px; */
}
.carousel .carouselPrev {
  transform: rotate(180deg);
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom: -65px;
}


@media only screen and (max-width: 1300px) {
  .carousel .carouselPrev {
    margin-left: 40px !important;
    transform: rotate(180deg);
  }
  .carousel .carouselNext {
    margin-left: -40px !important;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
  }
}
@media only screen and (max-width: 700px) {
  .carousel .carouselPrev,
  .carousel .carouselNext {
    display: none;
  }
  .card {
    margin-right: 20px;
  }
  .carousel h2:nth-child(1) {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .carousel {
    width: 95%;
    margin-right: 0px;
  }
  .carousel {
    margin-bottom: 48px;
    padding: 0px;
  }
}
