.loginTop {
  background: linear-gradient(180deg, #151515 0%, #000000 100%);
  height: 100vh;
}
.loginTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loginTop .row {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-image: url("./../../img/BG.svg");
  background-position: 100%;
  background-size: cover;
}
.loginTop .left {
  width: 50%;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(35px);
  border-radius: 0px 60px 60px 0px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.loginTop .left .logo {
  position: absolute;
  top: 45px;
  left: 10%;
  display: flex;
  align-items: center;
}
.loginTop .left .logo img {
  width: 30px;
  height: 30px;
}
.loginTop .left .logo h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  line-height: 17px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #a6c9f1 0%, #139de0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-left: 8px;
}
.loginTop .right {
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginTop .right img {
  max-width: 772px;
  width: 80%;
}

@media only screen and (max-width: 992px) {
  .loginTop .row {
    flex-wrap: wrap;
  }
  .loginTop .left {
    width: 100%;
    border-radius: 0px !important;
    backdrop-filter: blur(0px);
  }
  .loginTop .right {
    display: none;
  }
  .loginTop .left .logo {
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
