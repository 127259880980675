@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.homeTop {
  width: 100%;
  background: linear-gradient(180deg, #151515 0%, #000000 100%);
}
.homeTop .homeInner {
  background-image: url("./../../img/allmoviesBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
