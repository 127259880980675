.watchLaterTop {
    width: 100%;
    transition: 0.4s;
}
.watchLaterTop .headerContent {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.watchLaterTop .headerContent .CaretLeft {
    display: none;
}
.watchLaterTop .headerContent img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
}
.watchLaterTop .headerContent h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #ffffff;
    font-family: "Plus Jakarta Sans", sans-serif;
}

.watchLaterTop .headerContent {
    width: 100%;
}
.watchLaterTop .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
@media only screen and (max-width: 1400px) {
    .watchLaterTop .row {
        justify-content: space-between;
    }
}
@media only screen and (max-width: 1100px) {
    .watchLaterTop .headerContent {
        justify-content: center;
        position: relative;
    }
    .watchLaterTop .headerContent .CaretLeft {
        display: block;
        position: absolute;
        left: 0px;
    }
    .watchLaterTop {
        width: 100%;
    }
    .HeaderTopActive {
        margin-left: 0px;
        margin-right: 0px;
    }
    .watchLaterTop .headerContent .CaretLeft {
        display: block;
        cursor: pointer;
    }
    .watchLaterTop .headerContent h1 {
        font-size: 24px;
    }
    .watchLaterTop .headerContent img {
        width: 24px;
    }
}
@media only screen and (max-width: 769px) {
    /* .HeaderTopActive{
      margin-left: 100%;
    } */
}
