.HeaderTop {
  width: 100%;
  padding: 40px 5%;
}

/* menu for mobile ? */
.HeaderTop .MobileMenuTop {
  position: fixed;
  /* inset: 0; */
  top: 0px;
  left: 0px;
  bottom: 0px;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999999999;
  /* display: none; */
  transition: 0.4s;
  margin-left: -100%;
}

.HeaderTop .MobileMenuTopActive {
  margin-left: 0px !important;
}

.HeaderTop .MobileMenuTop .menuForMobile {
  max-width: 375px;
  width: 100%;
  /* position: fixed; */
  top: 0px;
  left: 0px;
  background: linear-gradient(180deg, #151515 0%, #000000 100%);
  bottom: 0px;
  background-position: 100% 100%;
  overflow: hidden;
  height: 100%;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu {
  width: 100%;
  height: 100%;
  padding: 25px 16px;
  background-image: url("./../../img/allmoviesBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: cover;
  object-fit: cover;
}

.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .header span {
  display: flex;
  align-items: center;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .header span img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .header span h3 {
  font-family: "Inter";
  font-style: normal;
  margin-left: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  /* Liner w white */

  background: linear-gradient(90deg, #a6c9f1 0%, #139de0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .twoEpisodeFreeMob {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  height: 70px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  backdrop-filter: blur(35px);
  border-radius: 20px;
  border: none;
  outline: none;
  box-shadow: none;
  flex-direction: column;
  margin-bottom: 36px;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu ul {
  display: block;
  width: 100%;
  list-style-type: none;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu ul li {
  display: flex;
  align-items: center;
  padding: 24px 0px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #ffff;
  opacity: 0.6;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu ul li:hover {
  opacity: 1;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu ul li img {
  margin-right: 16px;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .loginMobile {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 25px;
  left: 23px;
}
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .loginMobile p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans";
  margin-left: 16px;
  color: #ffffff;
}
.HeaderTop .headerInner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HeaderTop .headerInner .left {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;
  height: 70px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(35px);
  border-radius: 20px;
}
.HeaderTop .headerInner .left .ul {
  display: flex;
  align-items: center;
}
.HeaderTop .headerInner .left .ul a {
  text-decoration: none;
  color: #bcbcbc;
  margin:0 5px;

}
.HeaderTop .headerInner .left .ul li {
  font-family: "Plus Jakarta Sans", sans-serif;
  list-style-type: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  flex: none;
  cursor: pointer;
}
.HeaderTop .headerInner .left .ul li:hover {
  color: #fff;
}


.HeaderTop .headerInner .left form {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  width: 365px;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  position: relative;
}
.HeaderTop .headerInner .left form input {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}
.HeaderTop .headerInner .left form input::placeholder {
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}

.HeaderTop .headerInner .left form input:-ms-input-placeholder {
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}
.HeaderTop .headerInner .left form input::-ms-input-placeholder {
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}
.HeaderTop .headerInner .left form span {
  display: flex;
  align-items: center;
}
.HeaderTop .headerInner .left form span a {
  text-decoration: none;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 0px;
}


.HeaderTop .headerInner .right .profilePic .notification {
  width: 41.5px !important;
  height: 40px;
  margin: 0px 8px 0px 0px;
  border-radius: 14px;
  padding: 1px;
  z-index: 999999 !important;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  backdrop-filter: blur(35px);
  cursor: pointer;
  position: relative;
}
.HeaderTop .headerInner .left form .notification {
  width: 41.5px !important;
  height: 40px;
  margin: 0px 8px 0px 0px;
  border-radius: 14px;
  padding: 1px;
  display: none;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  backdrop-filter: blur(35px);
  cursor: pointer;
  position: relative;
}
.HeaderTop .headerInner .left form .notification span,
.HeaderTop .headerInner .right .profilePic .notification span {
  width: 100%;
  height: 100%;
  width: 40px;
  /* position: relative; */
  color: #fff;
  background: #393636;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
}
.HeaderTop .headerInner .left form .notification .notificationDropDown,
.HeaderTop .headerInner .right .profilePic .notification .notificationDropDown {
  position: absolute;
  width: 292px;
  height: 350px;
  overflow-y: auto;
  top: 130%;
  border-radius: 14px;
  z-index: 20;
  background: #393636;
  z-index: 999999999;
  right: -20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.HeaderTop .headerInner .left form .menuIcon {
  position: static !important;
  cursor: pointer;
  border-radius: 12px;
  display: none;
}
.HeaderTop .headerInner .left {
  position: relative;
}
.HeaderTop .headerInner .left form .mobileSearch .mobileForm {
  position: fixed;
  top: 100px;
  background: #232323;
  width: 100%;
  height: 60px;
  left: 0;
  right: 0;
  backdrop-filter: blur(35px);
  border-radius: 16px;
  padding: 10px 16px;
  z-index: 9999999;
}
.HeaderTop .headerInner .left form .mobileSearch .mobileForm form {
  width: 100%;
  background: #ffffff;
  position: relative;
  border-radius: 12px;
}
.HeaderTop .headerInner .left form .mobileSearch .mobileForm form .filter {
  position: absolute;
  display: block;
  right: 12px;
  bottom: 8px;
}
.HeaderTop .headerInner .left form .mobileSearch .mobileForm input {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  font-style: normal;
  padding: 0px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #1e2128;
}
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  input::placeholder {
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #1e2128;
}
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  input:-ms-input-placeholder {
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #1e2128;
}
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  input::-ms-input-placeholder {
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}
/* search drop down here ==== */
.HeaderTop .headerInner .left form .searchDropDown,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 100%;
  z-index: 99999;
  background: #232323;
  backdrop-filter: blur(35px);
  border-radius: 12px;
}

.HeaderTop .headerInner .left form .searchDropDown .searchMovieList,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  .searchMovieListMobile {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.HeaderTop
  .headerInner
  .left
  form
  .searchDropDown
  .searchMovieList:nth-last-child(2),
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  .searchMovieListMobile:nth-last-child(2) {
  border-bottom: none !important;
}
.HeaderTop .headerInner .left form .searchDropDown .searchMovieList img,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  .searchMovieListMobile
  img {
  width: 34px;
  height: 47px;
  border-radius: 6px;
  margin-right: 8px;
}
.HeaderTop .headerInner .left form .searchDropDown .btnSearchAll,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  .btnSearchAll {
  display: flex;
  align-items: center;
  padding: 12px;
  padding-top: 0px !important;
}
.HeaderTop .headerInner .left form .searchDropDown .searchMovieList h3,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  h3 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 4px;
}
.HeaderTop .headerInner .left form .searchDropDown .searchMovieList p,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  p {
  display: flex;
  align-items: center;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.HeaderTop .headerInner .left form .searchDropDown .searchMovieList p span,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  p
  span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  left: 0px;
  font-weight: 400;
  font-size: 12px;
  background: linear-gradient(90deg, #a6c9f1 0%, #139de0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.HeaderTop .headerInner .left form .searchDropDown .btnSearchAll button,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  .btnSearchAll
  button {
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 12px;
  padding: 0.5px;
}
.HeaderTop .headerInner .left form .searchDropDown .btnSearchAll button span,
.HeaderTop
  .headerInner
  .left
  form
  .mobileSearch
  .mobileForm
  .searchDropDownMobile
  .btnSearchAll
  button
  span {
  align-items: center;
  background: #393636;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
  color: #fff;
  width: 100%;
  padding: 8px 24px;
}

/* mobile .search here ==== */
.HeaderTop .headerInner .left form .mobileSearch {
  position: relative;
}

.HeaderTop .headerInner .right {
  display: flex;
  align-items: center;
  margin-left: 31px;
  justify-content: flex-end;
  width: 30%;
}
.HeaderTop .headerInner .right .twoEpisodeFree {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 38px;
  width: 212px;
  cursor: pointer;
  height: 70px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  backdrop-filter: blur(35px);
  border-radius: 20px;
  flex: none;
}
.HeaderTop .headerInner .right .twoEpisodeFree p,
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .twoEpisodeFreeMob p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  flex: none;
  margin-bottom: 10px;
}

.HeaderTop .headerInner .right .twoEpisodeFree h3,
.HeaderTop .MobileMenuTop .menuForMobile .innerMenu .twoEpisodeFreeMob h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  flex: none;
}
.HeaderTop .headerInner .right .profilePic {
  display: flex;
  align-items: center;
}
.HeaderTop .headerInner .right .profilePic p {
  font-style: normal;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin-right: 10px;
  margin-left: 49px;
}
.HeaderTop .headerInner .right .profilePic .profile {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  width: 56px;
  border: none;
  box-shadow: none;
  outline: none;
  height: 56px;
  border-radius: 14px;
  padding: 1px;
}
.HeaderTop .headerInner .right .profilePic .profile span {
  align-items: center;
  background: #393636;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
  color: #fff;
  width: 100%;
}
.HeaderTop .headerInner .right .profilePic .profile .dropDown {
  position: absolute;
  top: 130%;
  background: #232323;
  z-index: 99999;
  width: 292px;
  overflow-y: auto;
  margin-left: -420%;
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.HeaderTop .headerInner .left form .notification .notificationDropDown a,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px;
}
.HeaderTop .headerInner .left form .notification .notificationDropDown a:hover,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  a:hover {
  background: rgba(255, 255, 255, 0.05);
}
.HeaderTop
  .headerInner
  .left
  form
  .notification
  .notificationDropDown
  a
  .lefttt,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  a
  .lefttt {
  padding: 12px 13px;
}
.HeaderTop
  .headerInner
  .left
  form
  .notification
  .notificationDropDown
  a
  .lefttt
  h3,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  a
  .lefttt
  h3 {
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
}
.HeaderTop
  .headerInner
  .left
  form
  .notification
  .notificationDropDown
  a
  .lefttt
  p,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  a
  .lefttt
  p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 0px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}
.HeaderTop .headerInner .left form .notification .notificationDropDown a .dot,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  a
  .dot {
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 13px;
}
.HeaderTop .headerInner .left form .notification .notificationDropDown a:hover,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  a:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.HeaderTop .headerInner .left form .notification .notificationDropDown a .left {
  border-radius: 0px;
}
.HeaderTop .headerInner .right .profilePic .profile .dropDown a,
.HeaderTop .headerInner .right .profilePic .profile .dropDown span,
.HeaderTop
  .headerInner
  .left
  form
  .notification
  .notificationDropDown
  .notificationHeader,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  .notificationHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: none;
  padding: 20px 28px;
  border-radius: 0px;
}
.HeaderTop
  .headerInner
  .right
  .profilePic
  .profile
  .dropDown
  a:nth-last-child(1),
.HeaderTop .headerInner .right .profilePic .profile .dropDown span,
.HeaderTop
  .headerInner
  .left
  form
  .notification
  .notificationDropDown
  .notificationHeader {
  border-bottom: none !important;
}
.HeaderTop .headerInner .right .profilePic .profile .dropDown a h3,
.HeaderTop .headerInner .right .profilePic .profile .dropDown span h3,
.HeaderTop
  .headerInner
  .left
  form
  .notification
  .notificationDropDown
  .notificationHeader
  h3,
.HeaderTop
  .headerInner
  .right
  .profilePic
  .notification
  .notificationDropDown
  .notificationHeader
  h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 130%;
  margin-left: 20px;
  color: #ffffff;
}
@media only screen and (max-width: 1600px) {
  .HeaderTop .headerInner .right .profilePic p {
    margin-right: 5px;
    margin-left: 20px;
  }
  .HeaderTop .headerInner .right .twoEpisodeFree {
    width: 180px;
  }
  .HeaderTop .headerInner .left form {
    width: 340px;
  }
  .HeaderTop .headerInner .left .ul li {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1440px) {
  .HeaderTop .headerInner .left {
    padding: 10px 20px;
    z-index: 99999999;
  }
  .HeaderTop .headerInner .left form {
    width: 300px;
  }
  .HeaderTop .headerInner .right .profilePic p {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 1440px) {
  /* mobile .search here ==== */
  .HeaderTop .headerInner .left form .mobileForm {
    display: none !important;
  }
  .HeaderTop .headerInner .left {
    padding: 10px 20px;
    z-index: 99999999;
  }
}

@media only screen and (max-width: 1250px) {


  .HeaderTop .headerInner .left form .searchDropDown {
    display: none !important;
  }
  .HeaderTop .headerInner .left {
    width: 65%;
  }
  .HeaderTop .headerInner .right {
    width: 35%;
  }
  
  .HeaderTop .headerInner .left form {
    background: transparent;
    position: relative;
    width: 100px;
    display: flex;
    padding: 0px;
    justify-content: flex-end;
  }
  .HeaderTop .headerInner .left form input {
    display: none;
  }
  .HeaderTop .headerInner .left form span {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
  .HeaderTop .headerInner .left form .filter {
    display: none;
  }
  .HeaderTop .headerInner .left form .search {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: none;
  }
  .HeaderTop .headerInner .left form .mobileSearch {
    width: 20px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HeaderTop .headerInner .left form .menuIconNot {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  /* mobile .search here ==== */
  .HeaderTop .headerInner .left form .mobileForm {
    display: block;
  }
  .HeaderTop .headerInner .left {
    width: 100%;
  }
  .HeaderTop .headerInner .left form .notification {
    display: block;
  }
  .HeaderTop .headerInner .right {
    display: none;
  }
  .HeaderTop .headerInner .left .ul {
    display: none;
  }
  .HeaderTop .headerInner .left form .menuIcon {
    display: block;
  }
}

@media only screen and (max-width: 669px) {
  .HeaderTop .headerInner .left {
    padding: 10px 16px;
  }
  .HeaderTop .headerInner .left .logo {
    width: 40px;
  }
}



.marqueeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 30px;
  color: #ffffff;
  border-radius: 20px;
  font-family: "Inter";
  margin-bottom: 25px;
}

.promotionRed {
  background-color: red !important;
}

.promotionBlue {
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
}

.marqueeText {
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  position: absolute;
  top: 3px;
  left: 100%; /* Metni başlangıçta ekranın dışında konumlandırır */
}

@keyframes marquee {
  0% {
    left: 100%; /* Başlangıç konumu, ekranın dışında */
  }
  100% {
    left: -100%; /* Bitiş konumu, ekranın dışında */
  }
}
