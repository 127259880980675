@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.contactTop {
  width: 100%;
  max-width: 820px;
}
.contactTop .headerContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.contactTop .headerContent .contactInner {
  display: flex;
  align-items: center;
  position: relative;
}
.contactTop .headerContent .contactInner .caretLeft {
  display: none;
}
.contactTop .termHeader {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}
.contactTop .headerContent .contactInner img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.contactTop .headerContent .contactInner h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.contactTop .headerContent button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 45px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 12px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.contactTop .headerContent button span {
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
}
.contactTop .headerContent button span img {
  margin-right: 11.87px;
}

.contactTop textarea , .contactTop input{
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  resize: none;
  padding: 16px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  box-shadow: none;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
  margin-bottom: 20px;
}
.contactTop textarea:hover {
  background: rgba(255, 255, 255, 0.1);
}
.contactTop textarea:focus {
  background: rgba(255, 255, 255, 0.05);
}

.contactTop input {
  height: auto;
}
.contactTop .sendMessage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 260px;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 16px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #f5f5f5;
  margin-bottom: 16px;
}


.contactTop .editRow {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  margin-top: 25px;
}

.contactTop .editRow p {
  text-align: center;
  padding: 0 25px;
}

.contactTop .editRow .profile {
  margin-top: 25px;
  width: 220px;
  height: 220px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.contactTop .editRow .profile p {
  text-align: center;
  width: 100%;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
  text-align: center;
}

.contactTop .editRow .inputData form .btn {
  width: 23%;
  display: flex;
  justify-content: flex-end;
}

.contactTop .editRow .inputData {
  width: 100%;
}

.contactTop .editRow .inputData form {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  gap: 34px;
}

.contactTop .editRow .inputData form .name,
.contactTop .editRow .inputData form .pass {
  width: 100%;
}

.contactTop .sendMessage:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.contactTop .sendMessage:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

headerFaqs .row img {
  width: 40px;
  height: 40px;
}
headerFaqs p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1100px) {
  .contactTop .headerContent .contactInner {
    position: relative;
  }
  .contactTop .headerContent .contactInner .caretLeft {
    position: absolute;
    display: block;
    left: 0px !important;
    cursor: pointer;
  }
  .contactTop .headerContent .contactInner h1 {
    font-size: 24px;
  }
  .contactTop .headerContent .contactInner img {
    width: 24px;
  }
}
@media only screen and (max-width: 669px) {
  .contactTop .headerContent {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .contactTop .headerContent {
    margin-bottom: 24px !important;
  }
  .contactTop .headerContent .contactInner {
    width: 100%;
    justify-content: center;
  }
  .contactTop .headerContent button {
    margin-top: 24px;
    max-width: 380px;
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .contactTop textarea {
    height: 200px;
  }
  .contactTop .sendMessage {
    width: 100%;
  }
}
