@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.clientReviewsTop {
  margin-bottom: 24px;
}
.clientReviewsTop .reviewsCount {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.clientReviewsTop .reviewsCount h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
}

.clientReviewsTop .reviewsCount span {
  color: #bcbcbc;
  margin-left: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
}
.clientReviewsTop .review {
  width: 100%;
  /* background: linear-gradient(
    90deg,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  ); */
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 1px;
}

.clientReviewsTop .review .inner {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  padding: 32px;
  /* background: #393636; */
}
.clientReviewsTop .review .inner .headerReview {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.clientReviewsTop .review .inner .headerReview .left {
  display: flex;
  align-items: center;
}
.clientReviewsTop .review .inner .headerReview .left img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.clientReviewsTop .review .inner .headerReview .left h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 4px;
}

.clientReviewsTop .review .inner .headerReview .left p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}

.clientReviewsTop .review .inner .headerReview .totalReview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  width: 91px;
  height: 51px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}
.clientReviewsTop .review .inner .headerReview .totalReview img {
  width: 14px;
  margin-right: 14px;
}
.clientReviewsTop .review .inner .headerReview .totalReview p {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
}
.clientReviewsTop .review .inner .reviewMessage {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 16px;
}
.clientReviewsTop .review .inner .replyDeleteReview {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.clientReviewsTop .review .inner .replyDeleteReview span {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.clientReviewsTop .review .inner .replyDeleteReview span p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-left: 4px;
}

.clientReviewsTop .review .inner .replies {
  border-top: 1px solid #000000;
  padding-top: 16px;
  padding-left: 32px;
}

.clientReviewsTop .review .inner .replies .repliesInner {
  margin-bottom: 40px;
}
.clientReviewsTop .review .inner .replies form {
  width: 100%;
  transition: 0.5s;
}
.clientReviewsTop .review .inner .replies form textarea {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 24px;
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: none;
  color: #bcbcbc;
  resize: none;
  margin-bottom: 24px;
}
.clientReviewsTop .review .inner .replies form .btns {
  display: flex;
  align-items: center;
}
.clientReviewsTop .review .inner .replies form .btns .reply {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 260px;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  line-height: 130%;
  border: none;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  margin-right: 16px;
}
.clientReviewsTop .review .inner .replies form .btns .reply:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

.clientReviewsTop .review .inner .replies form .btns .reply:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

.clientReviewsTop .review .inner .replies form .btns .cancel {
  background: linear-gradient(
    90deg,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );

  width: 260px;
  outline: none;
  box-shadow: none;
  border: none;
  height: 45px;
  border-radius: 8px;
  padding: 1px;
}

.clientReviewsTop .review .inner .replies form .btns .cancel span {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 8px;
  background: #393636;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
}
@media only screen and (max-width: 992px) {
  .clientReviewsTop .reviewsCount h2 {
    font-size: 20px;
  }
  .clientReviewsTop .reviewsCount {
    margin-bottom: 24px;
  }
  .clientReviewsTop .reviewsCount span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 669px) {
  .clientReviewsTop .review .inner .headerReview .left img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  .clientReviewsTop .review .inner {
    padding: 0px;
  }

  .clientReviewsTop .review {
    background: rgba(255, 255, 255, 0);
  }
  .clientReviewsTop .review .inner .headerReview {
    margin-bottom: 8px;
  }
  .clientReviewsTop .review .inner .headerReview .left h4 {
    font-size: 14px;
  }
  .clientReviewsTop .review .inner .headerReview .totalReview {
    width: 70px;
    height: 40px;
  }
  .clientReviewsTop .review .inner .headerReview .totalReview img {
    margin-right: 10px;
  }
  .clientReviewsTop .review .inner .headerReview .left p {
    font-size: 12px;
  }
  .clientReviewsTop .review .inner .headerReview .totalReview p {
    font-size: 14px;
    font-weight: 500;
  }
  .clientReviewsTop .review .inner .reviewMessage {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .clientReviewsTop .review .inner .replies .repliesInner {
    margin-bottom: 24px;
  }
}
