@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.ForgorPass {
  max-width: 340px;
  width: 90%;
}
.ForgorPass h1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  margin-bottom: 8px;
}
.ForgorPass p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
  flex: none;
  text-align: center;
  margin-bottom: 32px;
}

.ForgorPass form {
  width: 100%;
}
.ForgorPass form .inputStroke {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  display: block;
  outline: none;
  margin-bottom: 16px;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 1px;
}
.ForgorPass form .inputStroke span {
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
  width: 100%;
}
.ForgorPass form .inputStroke:hover {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.ForgorPass form .inputStroke:hover span {
  background: #393636;
}
.ForgorPass form .inputStroke:focus-within {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.ForgorPass form .inputStroke span:focus-within {
  background: #393636;
}

.ForgorPass form input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 10px;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  margin-bottom: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #bcbcbc;
}

.ForgorPass form .followSteps {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  display: block;
  outline: none;
  margin-bottom: 16px;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 1px;
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.ForgorPass form .followSteps span {
  align-items: center;
  background: #393636;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
  width: 100%;
}

.ForgorPass form .createBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  max-width: 340px;
  width: 100% !important;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 16px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #f5f5f5;
  margin-bottom: 16px;
}
.ForgorPass form .createBtn:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.ForgorPass form .createBtn:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

@media only screen and (max-width: 669px) {
  .ForgorPass h1 {
    font-weight: 600;
    font-size: 20px;
  }
  .ForgorPass p {
    font-weight: 400;
    font-size: 14px;
  }
  .ForgorPass form .createBtn {
    font-size: 14px;
  }
}
