@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.detailTop {
  width: 90%;
  margin: 0px auto 100px auto;
  background: linear-gradient(
    90deg,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
  position: relative;
  padding: 6px;
  border-radius: 20px;
}
.detailTop .inner .loginGif {
  position: absolute;
  top: -60px;
  right: -70px;
  z-index: 1;
  width: 500px;
}
.detailTop .freeTwoEpisode {
  position: absolute;
  right: 50px;
  top: 70px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 39px;
  position: absolute;
  width: 239px;
  height: 72px;
  cursor: pointer;
  background: #ffffff;
  backdrop-filter: blur(35px);
  border-radius: 20px;
}
.detailTop .freeTwoEpisode p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
  color: #1e2128;
  margin-bottom: 8px;
}
.detailTop .freeTwoEpisode h5 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #559ae8 0%, #139de0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
}
.detailTop .inner {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: 20px;
  padding: 80px 0px;
  background: linear-gradient(180deg, #151515 0%, #000000 100%);
}
.detailTop .inner .logo {
  display: block;
  margin: auto;
  z-index: 10;
  position: relative;
}
.detailTop .inner .logoTxt {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  z-index: 10;
  position: relative;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #a6c9f1 0%, #139de0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 29px;
}

.detailTop .inner h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  max-width: 820px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  margin: 0px auto 24px auto;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.detailTop .inner p {
  font-family: "Plus Jakarta Sans", sans-serif;
  max-width: 520px;
  margin: auto;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 80px;
}

.detailTop .inner .offerDetails {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.detailTop .inner .offerDetails .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 206.2px;
  height: 150px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0px 10px;
}
.detailTop .inner .offerDetails .card img {
  display: block;
  margin: auto;
  margin-bottom: 21px !important;
}
.detailTop .inner .offerDetails .card p {
  font-style: normal;
  font-weight: 700;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 24px;
  line-height: 130%;
  opacity: 1;
  text-align: center;
  color: #1e2128;
}
.detailTop .inner a {
  text-decoration: none;
}
.detailTop .inner .choosePlan {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  outline: none;
  border: none;
  box-shadow: none;
  margin-top: 10px;
  margin-left:auto;
  margin-right: auto;
  width: 260px;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  cursor: pointer;
}

.detailTop .inner .registerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  outline: none;
  border: none;
  box-shadow: none;
  margin-top: 10px;
  margin-left:auto;
  margin-right: auto;
  width: 260px;
  height: 45px;
  background: white;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  line-height: 130%;
  color: black;
  cursor: pointer;
}

@media only screen and (max-width: 1700px) {
  .detailTop .inner .loginGif {
    top: -200px;
    right: -200px;
    z-index: 1;
  }
}
@media only screen and (max-width: 1300px) {
  .detailTop .inner .offerDetails {
    flex-wrap: wrap;
  }
  .detailTop .inner .offerDetails .card {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .detailTop .freeTwoEpisode {
    display: none;
  }
  .detailTop .inner .logoTxt {
    font-weight: 500;
    font-size: 20px;
  }
  .detailTop .inner h1 {
    font-size: 38px;
  }
  .detailTop .inner .loginGif {
    top: -100px;
    right: -150px;
    width: 300px;
    z-index: 1;
  }
}
@media only screen and (max-width: 769px) {
  .detailTop .inner h1 {
    font-size: 20px;
    line-height: 130%;
    z-index: 10;
    position: relative;
  }
  .detailTop .inner .offerDetails{
    padding-right: 5px;
    padding-left: 5px;
  }
  .detailTop .inner .offerDetails .card:nth-child(3) {
    flex: 1; /* Üçüncü kartı tam genişlik yapmak için flex kullanın */
  }

  .detailTop .inner p {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    z-index: 10;
    position: relative;
  }
  .detailTop {
    margin: 0px auto 48px auto;
  }
  .detailTop .inner {
    padding: 32px 0px;
  }
}
@media only screen and (max-width: 569px) {
  .detailTop .inner .offerDetails {
    width: 100%;
    justify-content: space-between;

  }
  .detailTop .inner .offerDetails .card {
    width: 48%;
    margin: 0px 0px 20px 0px;
  }
  .detailTop .inner .offerDetails .card:nth-child(5) {
    width: 100% !important;
    margin-bottom: 0px !important;
  }
  .detailTop .inner .offerDetails,
  .detailTop .inner p {
    margin-bottom: 32px;
  }
  .detailTop {
    padding: 2px;
  }
}
@media only screen and (max-width: 469px) {
  .detailTop .inner .offerDetails .card p {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
  }
}
