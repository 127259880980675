@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");

.allMoviesTop {
	width: 100%;
	background: linear-gradient(180deg, #151515 0%, #000000 100%);
}

.allMoviesTop .allmoviesInner {
	background-image: url("./../../img/allmoviesBg.png");
}

.allMoviesTop .allmoviesInner .moviesList {
	width: 90%;
	margin: auto;
}

.allMoviesTop .allmoviesInner .moviesList h2 {
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 130%;
	color: #ffffff;
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 40px;
}

.allMoviesTop .allmoviesInner .moviesList {
	margin-bottom: 100px;
}

.allMoviesTop .allmoviesInner .moviesList .row {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.allMoviesTop .allmoviesInner .moviesList .loadMoreBtn {
	width: 260px;
	height: 45px;
	background: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(7.5px);
	margin: auto;
	border-radius: 12px;
	display: block;
	border: none;
	outline: none;
	box-shadow: none;
	cursor: pointer;
	padding: 1px;
}

.allMoviesTop .allmoviesInner .moviesList .loadMoreBtn span {
	display: flex;
	flex-direction: row;
	border-radius: 12px;
	justify-content: center;
	align-items: center;
	background: transparent;
	width: 100%;
	height: 100%;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	font-family: "Plus Jakarta Sans", sans-serif;
	color: #ffffff;
}

.allMoviesTop .allmoviesInner .moviesList .loadMoreBtn:hover span {
	background: #393636;
}

.allMoviesTop .allmoviesInner .moviesList .loadMoreBtn:hover {
	background-image: linear-gradient(
			to right,
			rgba(64, 64, 85, 0.45) 0%,
			rgba(19, 157, 224, 0.45) 100%
	);
}

@media only screen and (max-width: 1400px) {
	.allMoviesTop .allmoviesInner .moviesList .row {
		justify-content: space-between;
	}
}

@media only screen and (max-width: 669px) {
	.allMoviesTop .allmoviesInner .moviesList .loadMoreBtn {
		width: 100%;
	}

	.allMoviesTop .allmoviesInner .moviesList .row {
		margin-bottom: 0px;
		padding-bottom: 0px !important;
	}

	.allMoviesTop .allmoviesInner .moviesList {
		margin-bottom: 48px;
	}
}

.pagination {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 20px 0;
	justify-content: center;
	color: #ffffff;
	align-items: center;
}

.pagination button {
	/*background-color: #f0f0f0;*/
	/*color: #333;*/
	/*cursor: pointer;*/
	padding: 5px 10px;
	text-align: center;
	/*text-decoration: none;*/
	/*transition: background-color 0.3s ease-in-out;*/
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	box-shadow: none;
	height: 45px;
	background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
	border-radius: 12px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	color: #ffffff;
	font-family: "Plus Jakarta Sans", sans-serif;
	margin: 4px;
	min-width: 40px;
}



.pagination button:disabled {
	opacity: .5;
}

.pagination span {
	display: block;
	margin-left: 6px;
	margin-right: 6px;
}

.pagination button.active {
	background: linear-gradient(90deg, #404055 0%, #41545d 100%, #139de0 100%);
	color: #ffffff;
}

