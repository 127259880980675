@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.detailTop {
  width: 100%;
  background: linear-gradient(180deg, #151515 0%, #000000 100%);
  overflow-x: hidden;
}

.detailTop .movieInner {
  background-image: url("./../../img/allmoviesBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.detailTop .movieInner .headerTop {
  margin-bottom: 100px;
  position: relative;
  padding:50px 0;
}

.detailTop .movieInner .trailer {
  max-width: 1100px;
  width: 90%;
  margin: auto;
}
.detailTop .movieInner .trailer h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 40px;
}
.detailTop .movieInner .social {
  width: 90%;
  margin: 0px auto 100px auto;
}
.detailTop .movieInner .headerTop {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 100vh !important;
}
.detailTop .movieInner .headerTop iframe {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}
/* .detailTop .movieInner .headerTop video {
  min-width: 100%;
  min-height: 100vh;
  top: 0px;
  z-index: 1;
} */
.detailTop .movieInner .headerTop .overlay {
  width: 100%;
  height: calc(100% - 119px) !important;
  position: absolute;
  top: 0;
  left: 0;
  background-size:cover;
  background-position: center;
  z-index: 2;
}


.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.8); /* Siyah overlay rengi (0.5 opaklık) */
}
.detailTop .movieInner .headerTop .overlay .movieContent {
  position: absolute;
  top: calc(50% + 50px);
  width: 90%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);

  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left {
  width: 65%;
  display: flex;
  align-items: center;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .poster {
  width: 400px;
  max-height: 520px;
  object-fit: cover;
  margin-right: 20px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 20px 0px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 16px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating {
  display: flex;
  align-items: center;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .saveSeries {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .saveSeries .bookmarkDesktop {
  width: 48px;
  height: 48px;
  margin-right: 6px;
  cursor: pointer;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .totalRate {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  width: 91px;
  height: 51px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  margin-right: 20px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .totalRate p {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-left: 10px;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .genre {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 165px;
  height: 42px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 24px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle1 {
  display: none;
  height: 46px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 12px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .btns button:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  max-width: 260px;
  width: 50%;
  border: none;
  outline: none;
  box-shadow: none;

  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-right: 20px;
}
.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .btns button:nth-child(2) {
  max-width: 260px;
  width: 50%;
  border: none;
  outline: none;
  box-shadow: none;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  height: 45px;
  padding: 1px;
}

.detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .btns button:nth-child(2) span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  background: #393636;
}

/* right section here ============ */
.detailTop .movieInner .topcast {
  width: 100%;
  padding: 0 5%;
}

.detailTop .movieInner .topcast > h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 24px;
}

.detailTop .movieInner .topcast .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 50px;
  margin-bottom: 100px;
}

.detailTop .movieInner .topcast .row .cast {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 150px;
}

.detailTop .movieInner .topcast .row .cast img {
  width: 150px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: solid 1px #372a4b;
}

.detailTop .movieInner .topcast .row .cast p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 0;
}

@media only screen and (max-width: 1400px) {
  .detailTop .movieInner .headerTop .overlay .movieContent .left .poster {
    width: 350px;
  }
}
@media only screen and (max-width: 1240px) {
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle1 {
    display: block;
  }
  .detailMovie .top .descrip {
    height: auto !important;
    margin-bottom: 0px !important;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent {
    /* bottom: -200px; */
    position: static;
    transform: none;
    flex-wrap: wrap;
    top: 140px;
  }
  .detailTop .movieInner .headerTop {
    overflow: visible;
  }

  .detailTop .movieInner .headerTop .overlay .movieContent .left {
    width: 100%;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie {
    justify-content: flex-start;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .right {
    width: 100%;
    display: none;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .poster {
    width: 162px;
    margin-bottom: 16px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .totalRate p,
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle p {
    font-weight: 600;
    font-size: 16px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .totalRate {
    margin-right: auto;
    margin-top: 10px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .newRow {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .newRow .totalRate {
    margin-right: 10px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .totalRate {
    width: 87px;
    height: 46px;

    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 10px 16px;
    margin-bottom: 0px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .totalRate p {
    margin-bottom: 0px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .rating .genre {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
    margin-right: auto;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle2 {
    display: none;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top .subtitle p {
    margin-bottom: 0px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .top p {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 24px;
  }
  .detailTop .movieInner .castBtm {
    display: block;
    margin-top: 200px;
  }
}

@media only screen and (max-width: 569px) {
  .detailTop .movieInner .headerTop {
    margin-bottom: 250px;
  }
  .detailTop .movieInner .headerTop .overlay {
    height: auto !important;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent {
    position: relative;
    top: 0;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .btns {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }

  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .btns button:nth-child(1) {
    margin-bottom: 16px;
    width: 100%;
    margin-right: 0px;
  }
  .detailTop .movieInner .headerTop .overlay .movieContent .left .detailMovie .btns button:nth-child(2) {
    width: 100% !important;
    margin-right: 0px;
  }
}
