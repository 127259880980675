* {
	padding: 0px;
	box-sizing: border-box;
	margin: 0px;
}

.active {
	font-weight: 700;
	font-size: 20px;
	line-height: 130%;
	color: #ffffff !important;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}
