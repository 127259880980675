@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.privacyPolicyTop {
  width: 100%;
}
.privacyPolicyTop .privacyHeader {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

.privacyPolicyTop .privacyHeader .CaretLeft {
  position: absolute;
  left: 0px;
  display: none;
}
.privacyPolicyTop .privacyHeader img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.privacyPolicyTop .privacyHeader h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.privacyPolicyTop .detail h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 16px;
}
.privacyPolicyTop .detail p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
}
.privacyPolicyTop .detail p:nth-child(1) {
  margin-bottom: 24px;
}

.privacyPolicyTop .detail h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 8px;
}
.privacyPolicyTop .detail .mb16 {
  margin-bottom: 16px;
}

.privacyPolicyTop .detail .mb24 {
  margin-bottom: 24px;
}
@media only screen and (max-width: 1100px) {
  .privacyPolicyTop .privacyHeader {
    justify-content: center;
  }
  .privacyPolicyTop .privacyHeader .CaretLeft {
    display: block;
    cursor: pointer;
  }
  .privacyPolicyTop .privacyHeader h1 {
    font-size: 24px;
  }
  .privacyPolicyTop .privacyHeader img {
    width: 24px;
  }
}
@media only screen and (max-width: 669px) {
  .privacyPolicyTop .detail h2,
  .privacyPolicyTop .detail h3 {
    font-size: 16px;
  }
  .privacyPolicyTop .detail p,
  .privacyPolicyTop .detail p:nth-child(1) {
    font-size: 14px;
  }
}
