@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");

.paymentPlanTop {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.paymentPlanTop h1 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    max-width: 340px;
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
    text-align: center;
    margin-bottom: 8px;
}

.paymentPlanTop .paraTop {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    margin: auto;
    font-weight: 500;
    max-width: 340px;
    width: 100%;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    opacity: 0.6;
    flex: none;
    text-align: center;
}

.paymentPlanTop .warningMessage {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    margin: auto;
    font-weight: 500;
    max-width: 340px;
    width: 100%;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: red;
    margin-bottom: 16px;
}

.paymentPlanTop .row {
    max-width: 700px;
    width: 90%;
    margin: 24px auto 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 4px;
}

.paymentPlanTop .row .bronze,
.paymentPlanTop .row .silver,
.paymentPlanTop .row .golden,
.paymentPlanTop .row .platinum {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px;
    position: relative;
    height: 103px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 1px;
    margin-bottom: 20px;
    cursor: pointer;
}

.paymentPlanTop .row .bronze .inner,
.paymentPlanTop .row .silver .inner,
.paymentPlanTop .row .golden .inner,
.paymentPlanTop .row .platinum .inner {
    height: 100%;
    border-radius: 20px;
    padding: 24px 0px !important;
    width: 100%;
}

.paymentPlanTop .row .bronze:hover,
.paymentPlanTop .row .silver:hover,
.paymentPlanTop .row .golden:hover,
.paymentPlanTop .row .platinum:hover {
    background-image: linear-gradient(
            to right,
            rgba(64, 64, 85, 0.45) 0%,
            rgba(19, 157, 224, 0.45) 100%
    );
}

.paymentPlanTop .row .bronze:hover .inner,
.paymentPlanTop .row .silver:hover .inner,
.paymentPlanTop .row .golden:hover .inner,
.paymentPlanTop .row .platinum:hover .inner {
    background: #393636;
}

.paymentPlanTop .row .bronze h2,
.paymentPlanTop .row .silver h2,
.paymentPlanTop .row .golden h2,
.paymentPlanTop .row .platinum h2 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
}

.paymentPlanTop .row .bronze p,
.paymentPlanTop .row .silver p,
.paymentPlanTop .row .golden p,
.paymentPlanTop .row .platinum p {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
}

.paymentPlanTop .row .bronze p span,
.paymentPlanTop .row .silver p span,
.paymentPlanTop .row .golden p span,
.paymentPlanTop .row .platinum p span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #bcbcbc;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.paymentPlanTop .paymentMethod {
    max-width: 340px;
    width: 90%;
    margin: auto;
}

.paymentPlanTop .paymentMethod .rowCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}

.paymentPlanTop .paymentMethod .rowCard button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    gap: 10px;
    width: 160px;
    height: 44px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: none;
    outline: none;
    box-shadow: none;
    font-style: normal;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    font-family: "Plus Jakarta Sans", sans-serif;
    align-items: center;
    color: #ffffff;
    margin-top: 8px;
    padding: 1px;
}

.paymentPlanTop .paymentMethod .rowCard button:hover {
    background-image: linear-gradient(
            to right,
            rgba(64, 64, 85, 0.45) 0%,
            rgba(19, 157, 224, 0.45) 100%
    );
}

.paymentPlanTop .paymentMethod .rowCard button span {
    height: 100%;
    width: 100%;
    display: flex;
    font-family: "Plus Jakarta Sans", sans-serif;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.paymentPlanTop .paymentMethod .rowCard button span img {
    margin-right: 10px;
}

.paymentPlanTop .paymentMethod .rowCard button:hover span {
    background: #393636;
}

.paymentPlanTop .paymentMethod .paymentCreditCard .inputStroke {
    display: flex;
    font-family: "Plus Jakarta Sans", sans-serif;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    display: block;
    outline: none;
    margin-bottom: 16px;
    box-shadow: none;
    position: relative;
    text-decoration: none;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    padding: 1px;
}

.paymentPlanTop .paymentMethod .paymentCreditCard .inputStroke span {
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border: none;
    position: relative;
    width: 100%;
}

.paymentPlanTop .paymentMethod .paymentCreditCard .inputStroke span p {
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #bcbcbc;
    right: 16px;
}

.paymentPlanTop .paymentMethod .payBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    max-width: 340px;
    width: 100% !important;
    height: 45px;
    background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
    border-radius: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 16px;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: #f5f5f5;
    margin-bottom: 16px;
}

.paymentPlanTop .paymentMethod .payBtn:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

.paymentPlanTop .paymentMethod .payBtn:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

@media only screen and (max-width: 730px) {
    .paymentPlanTop .row {
        max-width: 340px;
        width: 90%;
    }

    .paymentPlanTop .row .bronze,
    .paymentPlanTop .row .silver,
    .paymentPlanTop .row .golden,
    .paymentPlanTop .row .platinum {
        height: 80px;
    }

    .paymentPlanTop .row .bronze .inner,
    .paymentPlanTop .row .silver .inner,
    .paymentPlanTop .row .golden .inner,
    .paymentPlanTop .row .platinum .inner {
        padding: 16px 0px !important;
    }

    .paymentPlanTop .row .bronze h2,
    .paymentPlanTop .row .silver h2,
    .paymentPlanTop .row .golden h2,
    .paymentPlanTop .row .platinum h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .paymentPlanTop .row .bronze p span,
    .paymentPlanTop .row .silver p span,
    .paymentPlanTop .row .golden p span,
    .paymentPlanTop .row .platinum p span {
        font-weight: 400;
        font-size: 12px;
    }
}

@media only screen and (max-width: 669px) {
    .paymentPlanTop h1,
    .paymentPlanTop .paymentMethod h1 {
        font-weight: 600;
        font-size: 20px;
    }

    .paymentPlanTop p {
        font-weight: 400;
        font-size: 14px;
    }
}

.badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: green;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.lowerPlanMessage {
    font-size: 12px;
    color: red;
    text-align: center;
    margin-top: 8px;
}
