@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.historyTop {
    width: 100%;
}
.historyTop .headerContent {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}
.historyTop .headerContent img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
}
.historyTop .headerContent .CaretLeft {
    display: none;
    cursor: pointer;
}
.historyTop .headerContent h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #ffffff;
    font-family: "Plus Jakarta Sans", sans-serif;
}

.historyTop .moviesList {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1400px) {
    .historyTop .moviesList {
        justify-content: space-between;
    }
}
@media only screen and (max-width: 1100px) {
    .historyTop .headerContent {
        justify-content: center;
        position: relative;
    }
    .historyTop .headerContent .CaretLeft {
        display: block;
        position: absolute;
        left: 0px;
    }
    .historyTop .headerContent h1 {
        font-size: 24px;
    }
    .historyTop .headerContent img {
        width: 24px;
    }
    .historyTopActive {
        margin-left: 0px;
        margin-right: 0px;
    }
}
@media only screen and (max-width: 769px) {
    .historyTopActive {
        margin-left: 100%;
    }
}
