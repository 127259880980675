@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.slick-list {
  padding: 0 20% 20% 0 !important;
}
.carousel {
  width: 100%;
  margin: auto;
  margin-bottom: 100px;
  overflow: hidden;
}
.slick-list {
  margin-left: -20px !important;
}
.carousel .carouselPrev {
  transform: rotate(180deg);
}
.carousel .tabContent {
  display: block !important;
}
.slick-slide.slick-center img {
  transform: scale(1.1);
}
.card {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  cursor: pointer;
  margin: 0px 15px;
  overflow: hidden;
  width: 98%;
  transform: translate3d(14.6%, 0px, 0px);
  height: 680px;
}
.card .multiImage {
  width: 100%;
  z-index: -1 !important;
  height: 100%;
  margin-bottom: 0px;
}

.carousel .slick-prev {
  left: -10px;
  z-index: 10;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  transform: rotate(180deg);
}
.carousel .slick-next {
  right: -10px !important;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom: -65px;
}
.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.custom-indicator li.slick-active {
  filter: grayscale(0%);
}

.card .cardContent {
  position: absolute;
  z-index: 99;
  top: 0px;
  padding: 40px 70px;
  height: 100%;

  width: 100%;
  border-radius: 40px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
}
.card .cardContent h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 16px;
}
.card .cardContent .title .category {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.card .cardContent .title .category h3 {
  margin-left: 10px;
  color: #fff
}
.card .cardContent .title .category p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-left: 10px;
}

.card .cardContent .title .detailPara {
  font-style: normal;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  max-width: 665px;
  width: 100%;
}

.card .cardContent .watchBtns {
  display: flex;
  align-items: center;
  gap: 20px;
}

.card .cardContent .watchBtns > button {
  flex:1;
  transition: all 0.15s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  max-width: 260px;
  min-width: 150px;
  height: 45px;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  line-height: 130%;
  cursor: pointer;
  box-shadow: inset 0 0 0 #000;
  backdrop-filter: blur(7.5px);
}

.card .cardContent .watchBtns > button:hover {
  box-shadow: inset 0 0 10px 0px #000, 0 0 5px 0px rgb(255 255 255 / 20%);
}

.card .cardContent .watchBtns .watchNow {
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  color: #ffffff;
}

.card .cardContent .watchBtns .watchTrailer {
  color: #ffffff;
  border: 1px solid #404055;
  background: rgba(255, 255, 255, 0.1);
}

.card .cardContent .watchBtns .moreDetail {
  color: #ffffff;
  border: 1px solid #404055;
  background: rgba(19, 157, 224, 0.1);
}

@media only screen and (max-width: 1340px) {
  .carouselPrev {
    margin-left: -25px;
  }
  .carouselNext {
    margin-left: 25px;
  }
  .card .cardContent h1 {
    font-size: 44px;
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 1100px) {
  .carouselPrev,
  .carouselNext {
    display: none;
  }
  .card,
  .card .multiImage {
    border-radius: 25px;
  }
}
@media only screen and (max-width: 992px) {
  .card .cardContent .watchBtns {
    margin-top:20px;
  }
  .card .cardContent {
    padding: 10px 20px;
  }
  .card .cardContent {
    justify-content: end;
  }
}
@media only screen and (max-width: 892px) {
  .card .cardContent h1 {
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 5px;
  }
  .card .cardContent .title .detailPara {
    display: none;
  }
  .card .cardContent .title .category p {
    font-size: 16px;
    line-height: 130%;
  }
}

@media only screen and (max-width: 592px) {
  .card {
    height: 209px;
    object-fit: contain;
    border-radius: 12px;
    transform: translate3d(13%, 0px, 0px);
    overflow: hidden;
  }
  .carousel {
    margin-bottom: 48px;
  }
  .card .cardContent {
    border-radius: 12px !important;
  }
  .card .multiImage {
    width: 139%;

    border-radius: 12px !important;
    object-fit: cover;
    margin-left: -100px;
    overflow: hidden;
  }
  .card .cardContent .watchBtns{
    gap:10px;
  }
  .card .cardContent .watchBtns > button{
    min-width: 120px;
  }
  .card .cardContent .watchBtns .moreDetail{
    display: none;
  }
}
.badge {
font-family: "Plus Jakarta Sans", sans-serif;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  color: #ffffff;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-right: 10px;
}
