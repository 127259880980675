/* Genel stil ayarları */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");

.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}

.carousel {
	width: 90%;
	margin: 100px auto;
}

.carousel .seasonCount {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
}

.carousel .seasonCount .tab {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 130%;
	font-family: "Plus Jakarta Sans", sans-serif;
	color: #bcbcbc;
	cursor: pointer;
	margin: 0px 12px;
}

.carousel .seasonCount .ActiveTab {
	color: #ffffff !important;
}

.carousel .ActiveTab {
	color: #ffffff;
}

.carousel .tabContent {
	display: none !important;
	transition: 0.4s;
}

.carousel .findActiveContent {
	display: block !important;
}

.card .playSeriesEp {
	width: 60px;
	height: 60px;
	position: absolute;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	top: 30%;
	z-index: 9;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 50%;
	backdrop-filter: blur(35px);
	display: none;
}

.card:hover .playSeriesEp {
	display: block;
}

.card {
	height: auto;
	margin: 0px 10px;
	position: relative;
	border-radius: 20px;
	cursor: pointer;
}

.card .posterSeries {
	margin-bottom: 16px;
	border-radius: 20px;
	padding: 1px;
}

.card .posterSeries:hover {
	background: linear-gradient(20.67deg, rgba(0, 118, 255, 0.8) 0%, rgba(70, 70, 70, 0.4) 17.19%, rgba(70, 70, 70, 0.4) 80.21%, rgba(0, 118, 255, 0.8) 100%);
}

.card .multi__image {
	width: 100%;
	height: 100%;
	margin-bottom: 0px;
	border-radius: 20px;
}

.card h2 {
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 130%;
	color: #ffffff;
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 8px;
}

.card p {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	font-family: "Plus Jakarta Sans", sans-serif;
	color: #bcbcbc;
}

.carousel .slick-prev {
	left: -80px;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	transform: rotate(180deg);
}

.carousel .slick-next {
	right: -40px;
	border-bottom-left-radius: 7px;
	border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
	display: none;
}

.custom-indicator {
	bottom: -65px;
}

.custom-indicator li {
	width: 50px;
	height: 50px;
	filter: grayscale(100%);
}

.custom-indicator li.slick-active {
	filter: grayscale(0%);
}

.carousel .carouselPrev {
	transform: rotate(180deg);
}

.carousel h2:nth-child(1) {
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 130%;
	font-family: "Plus Jakarta Sans", sans-serif;
	color: #ffffff;
	margin-bottom: 40px;
}

.episodesGrid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px 10px;
}

.showMoreContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 0;
	gap: 15px;
}

.showMoreContainer .displayInfo {
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 130%;
	font-family: "Plus Jakarta Sans", sans-serif;
	color: #ffffff;
	text-align: center;
}

.showMoreContainer .showMore {
	flex: 1;
	transition: all 0.15s ease-in-out;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	max-width: 260px;
	min-width: 150px;
	height: 45px;
	border: none;
	outline: none;
	box-shadow: none;
	border-radius: 12px;
	font-style: normal;
	font-weight: 600;
	font-family: "Plus Jakarta Sans", sans-serif;
	font-size: 16px;
	line-height: 130%;
	cursor: pointer;
	box-shadow: inset 0 0 0 #000000;
	backdrop-filter: blur(7.5px);
	background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
	color: #ffffff;
}

.showMoreContainer .showMore:hover {
	box-shadow: inset 0 0 10px 0px #000000, 0 0 5px 0px rgb(255 255 255 / 20%);
}

@media only screen and (max-width: 1300px) {
	.carousel .carouselPrev {
		margin-left: 40px !important;
		transform: rotate(180deg);
	}

	.carousel .carouselNext {
		margin-left: -40px !important;
		border-bottom-left-radius: 7px;
		border-top-left-radius: 7px;
	}

	.episodesGrid {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media only screen and (max-width: 1024px) {
	.episodesGrid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media only screen and (max-width: 700px) {
	.carousel .carouselPrev,
	.carousel .carouselNext {
		display: none;
	}

	.card {
		margin-right: 20px;
		margin-left: 0px;
	}

	.carousel h2:nth-child(1) {
		font-size: 24px;
		margin-bottom: 24px;
	}

	.carousel {
		width: 95%;
		margin-right: 0px;
	}

	.carousel {
		margin-bottom: 48px;
		padding: 0px;
	}
}

@media only screen and (max-width: 669px) {
	.carousel {
		margin-top: 0px !important;
		margin-bottom: 52px !important;
	}

	.card:hover .playSeriesEp {
		display: none;
	}

	.episodesGrid {
		grid-template-columns: repeat(1, 1fr);
	}
}

.episodeItem {
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-bottom: 20px;
	padding: 10px;
	border-radius: 20px;
	background-color: rgba(0, 0, 0, 0.1);
	cursor: pointer;

	font-family: "Plus Jakarta Sans", sans-serif;
}

.episodeImage img {
	display: block;
	max-width: 100%;
	height: 100px;
	border-radius: 10px;
	margin-right: 20px;
}

.episodeInfo {
	flex: 1;
}

.episodeInfo h5 {
	font-size: 18px;
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 8px;
}

.episodeInfo p {
	font-size: 14px;
	font-weight: 500;
	color: #bcbcbc;
}

/* Arama çubuğu stilizasyonu */
.search-bar {
	display: flex;
	align-items: center;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	padding: 8px 12px;
	margin-bottom: 20px;
}

/* Arama çubuğu giriş alanı stilizasyonu */
.search-bar input {
	flex: 1;
	border: none;
	outline: none;
	background: none;
	font-family: "Plus Jakarta Sans", sans-serif;
	font-size: 16px;
	color: #ffffff;
	width: 100%; /* Arama çubuğu genişliği burada ayarlandı */
}

/* Arama çubuğu giriş alanı yer tutucu (placeholder) stilizasyonu */
.search-bar input::placeholder {
	color: rgba(255, 255, 255, 0.6);
}

/* Arama düğmesi stilizasyonu */
.search-bar button {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0;
}

/* Arama ikonunun stilizasyonu */
.search-icon {
	color: #ffffff;
	font-size: 20px;
	margin-right: 8px;
}

.episodesTable {
	width: 100%;
	border-collapse: collapse;
}

.episodesTable th, .episodesTable td {
	border: 1px solid #dddddd;
	padding: 8px;
}

.episodesTable tr:hover {
	background-color: #f5f5f5;
}

.episodesTable th {
	background-color: #4caf50;
	color: white;
}

.button {
	width: 150px;
	height: 45px;
	border: none;
	outline: none;
	box-shadow: none;
	cursor: pointer;
	background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
	border-radius: 12px;
	color: white !important;

	font-family: "Plus Jakarta Sans", sans-serif !important;
}

.button:hover {
	background: black !important;
	color: white;
}

@media (max-width: 768px) {
	.episodeImage {
		display: none;
	}
}
