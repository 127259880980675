@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.starRating {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  max-width: 680px;
  width: 100%;
  height: 121px;
  /* White 5% */
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 992px) {
  .starRating {
    padding: 16px 8px;
    height: auto;
    margin-bottom: 16px;
  }
}
