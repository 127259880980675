html,
body {
	width: 100vw;
	overflow-x: hidden;
}

.active:hover {
	opacity: 1;
}

.active {
	opacity: 1 !important;
	color: #ffffff !important;
}

.d-none {
	pointer-events: none !important;
	opacity: 0 !important;
}

.d-noned {
	display: none !important;
	pointer-events: none !important;
	opacity: 0 !important;
}

.video-js {
	width: 100%;
	/* min-height: 800px; */
	height: auto;
	border-radius: 20px !important;
	aspect-ratio: 16/9;
	display: block;
	overflow: hidden;
	margin-bottom: 100px;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
	content: "url('./img/Play.png')";
	z-index: 999;
	background-repeat: no-repeat;
	background-size: 46px;
	background-position: 50% calc(50% - 0px);
	border: none !important;
	box-shadow: none !important;
}

.video-js .vjs-big-play-button {
	font-size: 3em;
	line-height: 1.5em;
	border-radius: 50% !important;
	display: block;
	position: absolute;
	top: 47% !important;
	left: 50% !important;
	transition: translate(-50% -50%) !important;
	padding: 0;
	margin-top: -0.81666em;
	margin-left: -1.5em;
	cursor: pointer;
	opacity: 1;
	border: none !important;
	width: 100px !important;
	height: 100px !important;

	background-color: rgba(255, 255, 255, 0.05) !important;
	/* Glass w 70% */

	backdrop-filter: blur(35px);
	transition: all 0.4s;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
	content: url("./img/Play.png") !important;
}

@media only screen and (max-width: 1100px) {
	.video-js .vjs-big-play-button {
		width: 40px !important;
		height: 40px !important;
		top: 50% !important;
		margin-left: -0.4em !important;
		left: 50% !important;
		transition: translate(-50% -50%) !important;
	}

	.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
		content: url("./img/Playsmall.svg") !important;
		background: transparent !important;
		background-color: transparent !important;
		backdrop-filter: blur(0px) !important;
	}

	.video-js {
		border-radius: 12px !important;
		margin-bottom: 48px;
	}
}

/*
Top Movies Card
*/
.topTenMovieContainer {
	position: relative;
}

.topTenMovieContainer .slick-slider {
	z-index: 1;
}

.SliderMovieCard {
	position: relative;
	width: 100%;
	height: auto;
	aspect-ratio: 5/7;
	overflow: hidden;
}

.topTenNum {
	pointer-events: none;
	transition: all 0.3s ease-in-out;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	font-family: sans-serif;
	font-size: 175px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	-webkit-text-stroke: 5px white;
	-webkit-text-fill-color: black;

	z-index: 99;
}

.SliderMovieCard .CardImage {
	display: flex;
	width: 120%;
	aspect-ratio: 5/7;
	align-items: flex-start;
	justify-content: flex-start;

	overflow: hidden;
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

.SliderMovieCard .CardImage img {
	transition: all 0.4s ease-in-out;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.5;
}

.slick-current .SliderMovieCard {
	z-index: 9;
	overflow: visible;
}

.slick-current .SliderMovieCard .CardImage {
	border-radius: 20px;
	width: 125%;
}

.slick-current .SliderMovieCard .CardImage img {
	opacity: 1;
}

.slick-current .topTenNum {
	top: 10px;
	left: 30px;
	transform: translate(0, 0);
}

.SliderCardInfo {
	position: absolute;
	display: flex;
	width: auto;
	padding-bottom: 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;

	left: var(--wid);
	bottom: 0;
	z-index: 5;
	background-color: #0f0f0f;
	padding: 10px 25px 0 25px;
	box-sizing: border-box;
}

.SliderCardInfo > h4 {
	color: var(--White, #ffffff);
	font-family: Plus Jakarta Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%; /* 31.2px */
}

.SliderCardInfo .CardMeta {
	display: flex;
	align-items: center;
	gap: 8px;
}

.SliderCardInfo .CardMeta .CardRating {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
}

.SliderCardInfo .CardMeta .CardRating h3,
.SliderCardInfo .CardMeta .CardRating > span {
	color: var(--White, #ffffff);
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%; /* 20.8px */
}

.SliderCardInfo .CardMeta .CardGenre,
.SliderCardInfo .CardMeta .CardYear {
	color: var(--White, #ffffff);

	/* Text 2 */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%; /* 20.8px */
}

.SliderCardInfo .CardDesc {
	align-self: stretch;
	color: var(--White, #ffffff);

	/* Text 3 */
	font-family: Plus Jakarta Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 18.2px */
}

.topTenMovieContainerMobile .SliderMovieCard {
	padding: 0 5px;
}

.topTenMovieContainerMobile .SliderCardInfo {
	position: unset;
}

.topTenMovieContainerMobile .SliderMovieCard .CardImage {
	width: 100%;
	border-radius: 20px;
}

@media only screen and (max-width: 669px) {
	.pagination-first, .pagination-last,
	.pagination-dot {
		display: none !important;
	}
}
