@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.reviewsTop {
  width: 100%;
}
.reviewsTop .addReviews h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 40px;
}
.reviewsTop .addReviews textarea {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 24px;
  max-width: 680px;
  width: 100%;
  font-family: "Plus Jakarta Sans", sans-serif;
  border: none;
  outline: none;
  box-shadow: none;
  height: 200px;
  resize: none;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #bcbcbc;
  margin-bottom: 40px;
}
.reviewsTop .addReviews textarea:hover {
  background: rgba(255, 255, 255, 0.1);
}
.reviewsTop .addReviews textarea:focus {
  background: rgba(255, 255, 255, 0.05);
}
.reviewsTop .addReviews .post {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 260px;
  height: 45px;
  font-family: "Plus Jakarta Sans", sans-serif;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  outline: none;
  box-shadow: none;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 100px;
}
.reviewsTop .addReviews .post:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.reviewsTop .addReviews .post:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

@media only screen and (max-width: 992px) {
  .reviewsTop .addReviews h2 {
    margin-bottom: 24px;
    font-size: 20px;
  }
  .reviewsTop .addReviews textarea {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 569px) {
  .reviewsTop .addReviews .post {
    width: 100% !important;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 48px;
  }
}
