@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.carousel {
  width: 90%;
  margin: 100px auto;

  border-radius: 20px;
}
.carousel .seasonCount {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.carousel .seasonCount .tab {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
  cursor: pointer;
  margin: 0px 12px;
}
.carousel .seasonCount .ActiveTab {
  color: #ffffff !important;
}
.carousel .ActiveTab {
  color: #ffffff;
}
.carousel .tabContent {
  display: none !important;
  transition: 0.4s;
}
.carousel .findActiveContent {
  display: block !important;
}
.card .playSeriesEp {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  top: 30%;
  z-index: 9;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  backdrop-filter: blur(35px);
  display: none;
}
.card:hover .playSeriesEp {
  display: block;
}
.card {
  height: auto;
  margin: 0px 10px;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
}
.card .posterSeries {
  margin-bottom: 16px;
  border-radius: 20px;
  padding: 1px;
}
.card .posterSeries:hover {
  background: linear-gradient(
    20.67deg,
    rgba(0, 118, 255, 0.8) 0%,
    rgba(70, 70, 70, 0.4) 17.19%,
    rgba(70, 70, 70, 0.4) 80.21%,
    rgba(0, 118, 255, 0.8) 100%
  );
}
.card .multi__image {
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  border-radius: 20px;
}
.card h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 8px;
}
.card p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}
.carousel .slick-prev {
  left: -80px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  transform: rotate(180deg);
}
.carousel .slick-next {
  right: -40px;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom: -65px;
}
.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.custom-indicator li.slick-active {
  filter: grayscale(0%);
}

.carousel .carouselPrev {
  transform: rotate(180deg);
}
.carousel h2:nth-child(1) {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1300px) {
  .carousel .carouselPrev {
    margin-left: 40px !important;
    transform: rotate(180deg);
  }
  .carousel .carouselNext {
    margin-left: -40px !important;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
  }
}
@media only screen and (max-width: 700px) {
  .carousel .carouselPrev,
  .carousel .carouselNext {
    display: none;
  }
  .card {
    margin-right: 20px;
    margin-left: 0px;
  }
  .carousel h2:nth-child(1) {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .carousel {
    width: 95%;
    margin-right: 0px;
  }
  .carousel {
    margin-bottom: 48px;
    padding: 0px;
  }
}
@media only screen and (max-width: 669px) {
  .carousel {
    margin-top: 0px !important;
    margin-bottom: 52px !important;
  }
  .card:hover .playSeriesEp {
    display: none;
  }
}
