@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.filterTop {
  margin-bottom: 80px;
}
.filterTop form {
  width: 100%;
  margin-bottom: 24px;
}
.filterTop form input {
  width: 100%;
  height: 58px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #bcbcbc;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.filterTop .filterData {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.filterTop .filterData .byYear,
.filterTop .filterData .genre,
.filterTop .filterData .rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: auto;
  height: 50px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  margin-right: 20px;
  transition: 0.5s;
}
.filterTop .filterData .dropdownBtn {
  cursor: pointer;
}
.filterTop .filterData .active,
.filterTop .filterData .ratingActive,
.filterTop .filterData .genreActive {
  background: rgba(255, 255, 255, 0.15);
}
.filterTop .filterData .active .dropdownBtn,
.filterTop .filterData .ratingActive .dropdownBtn,
.filterTop .filterData .genreActive .dropdownBtn {
  transform: rotate(180deg);
  transition: 0.5s;
}
.filterTop .filterData .byYear p,
.filterTop .filterData .genre p,
.filterTop .filterData .rating p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-right: 11px;
}
/* .filterTop .showData {
  border: 1px solid red;
} */

.filterTop .filterData .byYear .cross,
.filterTop .filterData .rating .cross {
  margin-right: 0px !important;
  cursor: pointer !important;
}
.filterTop .showData .yeaName {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.filterTop .showData .yeaName > div {
  display: inline-block;
}

.filterTop .showData .yeaName div label {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  height: 50px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
}
.filterTop .showData .yeaName .checkedActive {
  background: rgba(255, 255, 255, 0.15) !important;
}
.filterTop .showData .yeaName div p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #ffffff;
  margin-left: 8px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.filterTop .showData .yeaName div .container {
  width: 20px;
  height: 20px;
  /* border: 1px solid red; */
  border-radius: 3px;
  /* overflow: hidden; */
  /* padding: 1px; */
  /* background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  ); */
}

.filterTop .showData .yeaName div .container input[type="checkbox"] {
  width: 100%;
  height: 100%;
  box-shadow: none;
}
.filterTop .showData .yeaName div .container input[type="checkbox"]:checked:before {
  background-color: green;
}
/* .filterTop
  .showData
  .yeaName
  div
  .container
  input[type="checkbox"]:disabled
  + label:before {
  border-color: none !important;
  border: none !important;
}

.filterTop
  .showData
  .yeaName
  div
  .container
  input[type="checkbox"]:checked:disabled
  + label:before {
    background-color: linear-gradient(
        to right,
        rgba(64, 64, 85, 0.45) 0%,
        rgba(19, 157, 224, 0.45) 100%
      );
      border-color: linear-gradient(
        to right,
        rgba(64, 64, 85, 0.45) 0%,
        rgba(19, 157, 224, 0.45) 100%
      );
} */

@media only screen and (max-width: 669px) {
  .filterTop .filterData .byYear,
  .filterTop .filterData .genre,
  .filterTop .filterData .rating {
    height: 36px;
    padding: 8px 12px;
    margin-right: 8px;
  }
  
  .filterTop .filterData .byYear p,
  .filterTop .filterData .genre p,
  .filterTop .filterData .rating p,
  .filterTop .showData .yeaName div p,
  .filterTop .showData .yeaName div p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .filterTop .filterData .byYear .cross,
  .filterTop .filterData .rating .cross {
    width: 24px;
  }
}
