@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");

.cardTop {
  max-width: 306px;
  padding: 48px 24px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cardTop h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 8px;
}

.cardTop h2 span {
  font-weight: 500;
  font-size: 20px;
  color: #bcbcbc;
}

.cardTop ul {
  margin: 24px 0;
  padding: 0;
  list-style: none;
}

.cardTop ul li {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 8px;
}

.cardTop button {
  width: 100%;
  height: 45px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTop button:hover {
  background-image: linear-gradient(
          to right,
          rgba(64, 64, 85, 0.45),
          rgba(19, 157, 224, 0.45)
  );
}

.cardTop button span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1350px) {
  .cardTop {
    width: 48%;
  }
}

@media only screen and (max-width: 1100px) {
  .cardTop {
    min-width: 300px;
    width: 48%;
  }
}

@media only screen and (max-width: 669px) {
  .cardTop {
    width: 100%;
    max-width: 506px;
  }

  .cardTop h2 {
    font-size: 20px;
  }
}