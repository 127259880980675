.notSubTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 781px;
}
.notSubTop div {
  width: 100%;
}
.notSubTop div img {
  display: block;
  margin: auto;
}
.notSubTop div p {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
  color: #bcbcbc;
  margin-bottom: 48px;
}
.notSubTop div button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 260px;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 16px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #f5f5f5;
  margin: auto;
  margin-bottom: 16px;
}
.notSubTop div button:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.notSubTop div button:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

@media only screen and (max-width: 469px) {
  .notSubTop div img {
    width: 30px;
    margin-bottom: 8px;
  }
  .notSubTop div p {
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 24px;
  }
  .notSubTop {
    height: 70vh;
  }
  .notSubTop div button {
    width: 100%;
  }
}
