@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.noResultTop {
  width: 90%;
  margin: auto;
}
.noResultTop h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 40px;
}
.noResultTop div {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.noResultTop div h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 130%;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}
