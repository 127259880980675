@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.SignIn {
  max-width: 340px;
  width: 90%;
}
.SignIn h1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  margin-bottom: 8px;
}
.SignIn p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
  flex: none;
  text-align: center;
  margin-bottom: 32px;
}
a {
  text-decoration: none;
}
.SignIn .googleBtn {
  max-width: 340px;
  width: 100%;
  height: 44px;
  color: #fff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  outline: none;
  box-shadow: none;
  border-image-slice: 1;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  /* background: rgba(255, 255, 255, 0.05); */
  background: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
  border-radius: 12px;
  padding: 1px;
}
.SignIn .googleBtn span {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  background: #393636;
  border-radius: 12px;
}
.SignIn .googleBtn img {
  margin-right: 10px;
}
.SignIn .orRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
  height: 0px;
  margin-bottom: 32px;
}
.SignIn .orRow p {
  margin-bottom: 1px;
}
.SignIn .orRow .line {
  border: 1px solid #bcbcbc !important;
  width: 44%;
  /* margin-top: -30px; */
}

.SignIn form {
  width: 100%;
}
.SignIn form .inputStroke {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  display: block;
  outline: none;
  margin-bottom: 16px;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 1px;
}
.SignIn form .inputStroke span {
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
  width: 100%;
}
.SignIn form .inputStroke:hover {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.SignIn form .inputStroke:hover span {
  background: #393636;
}
.SignIn form .inputStroke:focus-within {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.SignIn form .inputStroke span:focus-within {
  background: #393636;
}

.SignIn form input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 10px;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  margin-bottom: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #bcbcbc;
}
.SignIn form .pass {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  display: block;
  outline: none;
  margin-bottom: 16px;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 1px;
}
.SignIn form .pass:hover {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.SignIn form .pass:hover span {
  background: #393636;
}
.SignIn form .pass:focus-within {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.SignIn form .pass span:focus-within {
  background: #393636;
}
.SignIn form span {
  align-items: center;
  background: transparent;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
  width: 100%;
}

.SignIn form .pass input {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  top: 0px;
  left: 0px;
  padding-right: 40px;
  height: 100%;
  position: absolute;
}

.SignIn form .pass button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.SignIn form .pass button img {
  background: transparent;
}
.SignIn form input::placeholder {
  color: #bcbcbc;
  opacity: 1;
}

.SignIn form input:-ms-input-placeholder {
  color: #bcbcbc;
}

.SignIn form input::-ms-input-placeholder {
  color: #bcbcbc;
}
.SignIn form .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: auto;
  margin-top: 16px;
}
.SignIn form .container p {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
  flex: none;
  opacity: 1;
  cursor: pointer;
  order: 2;
  font-family: "Plus Jakarta Sans", sans-serif;
  align-self: stretch;
  flex-grow: 0;
}
.SignIn form .createBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  max-width: 340px;
  width: 100%;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  margin-top: 16px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #f5f5f5;
  margin-bottom: 16px;
}
.SignIn form .createBtn:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.SignIn form .createBtn:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.SignIn span {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bcbcbc;
}
.SignIn span a {
  text-decoration: none;
}
.SignIn span p {
  margin-bottom: 0px !important;
  cursor: pointer;
  margin-left: 5px;
}
@media only screen and (max-width: 669px) {
  .SignIn h1 {
    font-weight: 600;
    font-size: 20px;
  }
  .SignIn p {
    font-weight: 400;
    font-size: 14px;
  }
  .SignIn form .createBtn {
    font-size: 14px;
  }
}

.spinner {
  /* display: inline-block; */
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: #1697d8; /* Change the color to blue */
  border-top-width: 10px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
