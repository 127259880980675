.cardUiTop {
  width: 18%;
  margin-bottom: 40px;
  margin-right: 2.33%;
}
.cardUiTop:nth-child(5n + 0) {
  margin-right: 0 !important;
}
.cardUiTop .headerCard {
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 24px;
}
.cardUiTop .headerCard .imgDefault,
.cardUiTop .headerCard .hoverposter {
  width: 100%;
  aspect-ratio: 5/7;
  border-radius: 20px;
  z-index: -1;
  transition: 0.4s;
  object-fit: cover;
}

.cardUiTop .headerCard .rating {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 9999;
}
.cardUiTop .headerCard .rating div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardUiTop .headerCard .rating .save {
  margin-left: auto;
  display: block;
  cursor: pointer;
  display: none;
  margin-top: 16px;
  margin-right: -10px;
}
.cardUiTop .headerCard .rating .bookmark {
  margin-left: auto;
  margin-right: -10px;
  margin-top: 16px;
  cursor: pointer;
  display: none;
}
.cardUiTop .headerCard:hover .bookmark,
.cardUiTop .headerCard:hover .rating .save {
  display: block;
}
.cardUiTop .headerCard .rating .star {
  margin-right: 10px;
}
.cardUiTop .footer {
  width: 100%;
}
.cardUiTop .headerCard .rating h3 {
  margin-bottom: 0px !important;
}
.cardUiTop .footer h3,
.cardUiTop .headerCard .rating h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 8px;
}
.cardUiTop .headerCard .hoverCard {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  overflow: hidden;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  border-radius: 20px;
  z-index: 999;
}
.cardUiTop:hover .headerCard .hoverCard {
  display: block;
  padding: 30px;
  padding-top: 136px;
}

.cardUiTop .headerCard .hoverCard .details {
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: absolute;
}
.cardUiTop .headerCard .hoverCard .details h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 8px;
}
.cardUiTop .headerCard .hoverCard .details p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.cardUiTop .footer p {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
}

.cardUiTop .footer h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
}

@media only screen and (max-width: 1600px) {
  .cardUiTop .headerCard .rating .save,
  .cardUiTop .headerCard .rating .bookmark {
    width: 40px;
    margin-right: -10px;
    margin-top: 10px;
  }
  .cardUiTop .headerCard .hoverCard .details h4 {
    font-size: 24px;
  }
  .cardUiTop .headerCard .hoverCard,
  .cardUiTop .headerCard .hoverCard {
    padding: 10px;
  }
  .cardUiTop .footer h3,
  .cardUiTop .headerCard .rating h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1400px) {
  .cardUiTop {
    width: 32%;
  }
  .cardUiTop:nth-child(3n) {
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .cardUiTop:hover .headerCard .hoverCard {
    display: none;
  }
  .cardUiTop .headerCard:hover .rating .save,
  .cardUiTop .headerCard:hover .rating .bookmark {
    display: none;
  }
  .cardUiTop .headerCard .rating {
    right: 16px;
    top: 16px;
  }
  .cardUiTop .headerCard .bookmark,
  .cardUiTop .headerCard .rating .save {
    width: 30px !important;
  }
  .cardUiTop .headerCard .rating .star {
    width: 15px;
  }
}
@media only screen and (max-width: 892px) {
  .cardUiTop {
    width: 48%;
  }
  .cardUiTop:nth-child(2n) {
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 669px) {
  .cardUiTop {
    /* width: 100%; */
    margin-right: 0px;
  }
  .cardUiTop .headerCard {
    margin-bottom: 12px;
  }
  .cardUiTop .footer p span:nth-child(2) {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
