@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.topRate {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.topRate .starfield {
  width: 100%;
  height: auto;
  display: flex;
}
.topRate .starfield div {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topRate .starfield div .satrIcon {
  width: 35px;
  height: 35px;
  /* color: #ffffff;s */
  margin-bottom: 20px;
}
.topRate .starfield div p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
}
.star {
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

.star:hover/*:not(.active)*/ {
  transform: scale(1.085);
  color: #139de0;
}

.star.active {
  /* color:orange; */
  color: #139de0;
  font-size: 35px;
}
.rateCenter {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 992px) {
  .topRate .starfield div .satrIcon {
    width: 20px;
    margin-bottom: 0px;
  }
}
