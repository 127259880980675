@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.termConditionTop {
  width: 100%;
}
.termConditionTop .termHeader {
  display: flex;
  align-items: center;
}

.termConditionTop .termHeader {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}
.termConditionTop .termHeader img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.termConditionTop .termHeader h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.termConditionTop h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;

  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 16px;
}
.termConditionTop p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 16px;
}
@media only screen and (max-width: 1100px) {
  .termConditionTop .termHeader {
    position: relative;
    width: 100%;
    justify-content: center;
  }
  .termConditionTop .termHeader .CaretLeft {
    position: absolute;
    left: 0px;
    cursor: pointer;
  }
  .termConditionTop .termHeader h1 {
    font-size: 20px;
  }
  .termConditionTop .termHeader img {
    width: 24px;
  }
}
@media only screen and (max-width: 669px) {
  .termConditionTop h2 {
    font-size: 16px;
  }
  .termConditionTop p {
    font-size: 14px;
  }
}
