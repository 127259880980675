@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.newPass {
  max-width: 340px;
  width: 90%;
}
.newPass h1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  margin-bottom: 8px;
}

.newPass form {
  width: 100%;
}
.newPass form .pass {
  margin-bottom: 16px;
}
.newPass form input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 10px;
  width: 340px;
  height: 44px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  margin-bottom: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #bcbcbc;
}
.newPass form input:hover {
  background: rgba(255, 255, 255, 0.1);
}
/* .newPass form input:focus {
  border: 1px solid #ed584f;
} */
.newPass form .pass {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  display: block;
  outline: none;
  margin-bottom: 16px;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 1px;
}
.newPass form .pass:hover {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.newPass form .pass:focus-within {
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.newPass form .pass span:focus-within {
  background: #393636;
}
.newPass form .pass span {
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
  width: 100%;
}
.newPass form .pass:hover span {
  background: #393636;
}
.newPass form .pass input {
  width: 100%;
  background-color: transparent !important;
  top: 0px;
  left: 0px;
  padding-right: 40px;
  height: 100%;
  position: absolute;
}
.newPass form .pass button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.newPass form .pass button img {
  background: transparent;
}
.newPass form input::placeholder {
  color: #bcbcbc;
  opacity: 1;
}

.newPass form input:-ms-input-placeholder {
  color: #bcbcbc;
}

.newPass form input::-ms-input-placeholder {
  color: #bcbcbc;
}
.newPass form .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  margin-top: 16px;
}
.newPass form .container p {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
  flex: none;
  opacity: 1;
  cursor: pointer;
  order: 2;
  font-family: "Plus Jakarta Sans", sans-serif;
  align-self: stretch;
  flex-grow: 0;
}
.newPass form .createBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 340px;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 16px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #f5f5f5;
  margin-bottom: 16px;
}
.newPass form .createBtn:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.newPass form .createBtn:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.newPass span {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bcbcbc;
}
.newPass span a {
  text-decoration: none;
}
.newPass span p {
  margin-bottom: 0px !important;
  cursor: pointer;
  margin-left: 5px;
}
@media only screen and (max-width: 669px) {
  .newPass h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 32px;
  }
  .newPass p {
    font-weight: 400;
    font-size: 14px;
  }
  .newPass form .createBtn {
    font-size: 14px;
  }
}
