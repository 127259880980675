@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
.moviePlayerTop {
  width: 90%;
  margin: 0px auto 100px auto;
}
.moviePlayerTop .headerPlayer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  justify-content: space-between;
}
.moviePlayerTop .headerPlayer2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}
.moviePlayerTop .headerPlayer2 .prevEpisodeTopBtn,
.moviePlayerTop .headerPlayer2 .nextEpisodeTopBtn {
  display: none !important;
}
.moviePlayerTop .videoPlayer {
  position: relative;
  width: 100%;
  margin-bottom: 100px;
  overflow: hidden;
  /* height: 700px; */
  /* padding-top: 56.25%; */
  border-radius: 20px;
  background-color: black;
}
/* .moviePlayerTop .videoPlayer iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
} */
.moviePlayerTop .headerPlayer .seasonName {
  display: flex;
  align-items: center;
}

.moviePlayerTop .headerPlayer .prevEpisode,
.moviePlayerTop .headerPlayer2 .prevEpisodeTopBtn {
  width: 260px;
  height: 45px;
  border: none;
  outline: none;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 12px;
  cursor: pointer;
  padding: 1px;
}
.moviePlayerTop .headerPlayer .prevEpisode span,
.moviePlayerTop .headerPlayer2 .prevEpisodeTopBtn span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  border-radius: 12px;
}
.moviePlayerTop .headerPlayer .nextEpisode,
.moviePlayerTop .headerPlayer2 .nextEpisodeTopBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 45px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
}
.moviePlayerTop .headerPlayer .nextEpisode span,
.moviePlayerTop .headerPlayer2 .nextEpisodeTopBtn span {
  font-style: normal;
  font-weight: 600;
  width: 100%;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.moviePlayerTop .headerPlayer .nextEpisode:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.moviePlayerTop .headerPlayer .nextEpisode:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.moviePlayerTop .headerPlayer .prevEpisode:hover span {
  background: #393636;
}
.moviePlayerTop .headerPlayer .prevEpisode:hover {
  background: linear-gradient(
    90deg,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
}
.moviePlayerTop .headerPlayer .seasonName div {
  display: flex;
  flex-direction: column;
  margin: 0px 60px;
}
.moviePlayerTop .headerPlayer .seasonName div p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.moviePlayerTop .headerPlayer h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
}

.moviePlayerTop .headerPlayer img {
  width: 48px;
  height: 48px;
}
.moviePlayerTop .share {
  width: 100%;
}

.moviePlayerTop .share h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
}

.moviePlayerTop .share .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.moviePlayerTop .share .row div {
  width: 176px;
  height: 48px;
  margin: 0px 20px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
  padding: 1px;
}

.moviePlayerTop .share .row div span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}
.moviePlayerTop .share .row div span p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  margin-left: 20px;
}

.moviePlayerTop .share .row div span {
  background: #393636;
  border-radius: 10px;
}

@media only screen and (max-width: 1100px) {
  .moviePlayerTop .headerPlayer h2 {
    font-size: 20px;
  }
  .moviePlayerTop .headerPlayer img {
    width: 24px;
  }
  .moviePlayerTop {
    margin-bottom: 48px;
  }
  .moviePlayerTop .headerPlayer2 .prevEpisodeTopBtn,
  .moviePlayerTop .headerPlayer2 .nextEpisodeTopBtn {
    display: block !important;
  }
  .moviePlayerTop .headerPlayer {
    justify-content: center;
  }
  .moviePlayerTop .headerPlayer2 {
    justify-content: space-between;
  }
  .moviePlayerTop .headerPlayer .nextEpisode,
  .moviePlayerTop .headerPlayer .prevEpisode {
    display: none;
  }
  .moviePlayerTop .videoPlayer {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 669px) {
  .moviePlayerTop .headerPlayer h2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 4px;
  }
  .moviePlayerTop .headerPlayer .seasonName div p {
    font-weight: 400;
    font-size: 14px;
    margin-top: 4px;
  }
}
@media only screen and (max-width: 592px) {
  .moviePlayerTop .headerPlayer2 .prevEpisodeTopBtn,
  .moviePlayerTop .headerPlayer2 .nextEpisodeTopBtn {
    width: 48%;
  }
  .moviePlayerTop .headerPlayer2 .nextEpisodeTopBtn span {
    margin: 0px;
  }
  .moviePlayerTop .headerPlayer2 .prevEpisodeTopBtn span,
  .moviePlayerTop .headerPlayer2 .nextEpisodeTopBtn span {
    font-weight: 500;
    font-size: 14px;
    padding: 12px;
    width: 100%;
  }
}
