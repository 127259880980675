.subscriptionTop {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscriptionTop .inner {
  max-width: 960px;
  width: 100%;
  position: relative;
  border-radius: 16px;
}

.subscriptionTop .inner .CaretLeft {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.subscriptionTop h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 16px;
}

.subscriptionTop p {
  max-width: 600px;
  margin: 0 auto 40px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #bcbcbc;
}

.subscriptionTop .currentPlan {
  max-width: 960px;
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.subscriptionTop .currentPlan .planName h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 8px;
}

.subscriptionTop .currentPlan .planName h2 span {
  font-weight: 500;
  font-size: 18px;
  color: #bcbcbc;
}

.subscriptionTop .currentPlan .planDetails ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  gap: 16px;
  justify-content: center;
}

.subscriptionTop .currentPlan .planDetails ul li {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
}

.subscriptionTop .currentPlan .planDetails ul li span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ffffff;
}

.subscriptionTop .manageSub {
  margin-top: 16px;
}

.subscriptionTop .manageBtn {
  width: 180px;
  height: 45px;
  background-image: linear-gradient(to right, #2c3e50, #4ca1af);
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.subscriptionTop .manageBtn:hover {
  background-image: linear-gradient(to right, #34495e, #4ca1af);
  transform: scale(1.05);
}

.subscriptionTop .morePlan,
.subscriptionTop .morePlanWSub {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.subscriptionTop .morePlanWSub {
  width: 100%;
}

.planCard .changeBtn {
  width: 120px;
  margin-top: 12px;
  height: 40px;
  background: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.planCard .changeBtn:hover {
  background: #0056b3;
  transform: scale(1.05);
}

/* Butonlar */
.subscriptionTop button.changeBtn {
  width: 160px;
  height: 45px;
  border: none;
  border-radius: 12px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  margin: 8px 0;
}

.subscriptionTop button.changeBtn {
  background-image: linear-gradient(to right, rgba(19, 157, 224, 1), rgba(64, 64, 85, 1));
  color: #ffffff;
}

.subscriptionTop button.changeBtn:hover {
  background-image: linear-gradient(to right, rgba(19, 157, 224, 0.9), rgba(64, 64, 85, 0.9));
  transform: scale(1.05);
}

.subscriptionTop button.changeBtn:disabled {
  background-color: #555555;
  color: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.6;
}

/* Mobil uyum */
@media only screen and (max-width: 768px) {
  .subscriptionTop .currentPlan {
    align-items: center;
  }

  .subscriptionTop .currentPlan .planName,
  .subscriptionTop .currentPlan .planDetails {
    width: 100%;
    text-align: center;
  }

  .subscriptionTop .morePlanWSub {
    justify-content: center;
  }
}