@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");

.homeTop {
    width: 100%;
    background: linear-gradient(180deg, #151515 0%, #000000 100%);
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif; /* Genel font ayarı */
}

.homeTop .homeInner {
    background-image: url("./../../img/allmoviesBg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

/* Services.module.css */

.homeTop {
    background-color: #f8f9fa; /* Arka plan rengi */
}

.homeInner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.serviceHeader {
    text-align: center;
    max-width: 800px; /* Başlık ve açıklama içeriğinin maksimum genişliği */
    color: #fff; /* Başlık yazı rengi */
    margin: 0 auto; /* Başlık ve açıklamayı yatayda ortala */

}

.serviceHeader h1 {
    font-size: 24px;
}

.serviceHeader p {
    font-size: 16px;
}

.serviceCards {
    display: flex;
    justify-content: space-around;
}

.serviceCard {
    background-color: #fff; /* Kutu arka plan rengi */
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.serviceCard img {
    width: 50px; /* İkon boyutu */
    height: 50px;
    margin-bottom: 10px;
}

.serviceCard h2 {
    color: #000; /* Başlık yazı rengi */
    font-size: 18px;
    margin-bottom: 10px;
}

.serviceCard p {
    color: #000; /* Açıklama yazı rengi */
    font-size: 14px;
}
