@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap");
.footerTop {
  width: 90%;
  margin: 0px auto 100px auto;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(35px);
  border-radius: 20px;
  padding: 32px 42px;
}
.footerTop .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footerTop .row .col:nth-child(1) {
  width: 22%;
}
.footerTop .row .col:nth-child(2),
.footerTop .row .col:nth-child(3) {
  width: 18%;
}
.footerTop .row .col:nth-child(4) {
  width: 20%;
}
.footerTop .row .col:nth-child(5) {
  width: 18%;
}
.footerTop .row .col .logo {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.footerTop .row .col .logo h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-left: 16px;
  line-height: 29px;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  background: linear-gradient(90deg, #a6c9f1 0%, #139de0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footerTop .row .col:nth-child(1) p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
}

.footerTop .row .col h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  margin-bottom: 20px;
}
.footerTop .row .col ul li,
.footerTop .row .col ul li a {
  list-style-type: none;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 16px;
}
.footerTop .row .col ul li:nth-child(last) {
  margin-bottom: 0px !important;
}
.footerTop .row .col .social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 24px;
}
.footerTop .row .col .social img {
  cursor: pointer;
}
.footerTop .row .col:nth-child(4) p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #ffffff;
  text-align: center;
  margin-bottom: 8px;
}
.footerTop .row .col:nth-child(4) h2 {
  text-align: center;
}
.footerTop .copyWrite {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-top: 48px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
}
@media only screen and (max-width: 1440px) {
  .footerTop .row .col .logo h1,
  .footerTop .row .col h2,
  .footerTop .row .col:nth-child(4) h2 {
    font-size: 20px;
  }
  .footerTop .row .col .logo img {
    width: 40px;
  }
  .footerTop .row .col:nth-child(1) p,
  .footerTop .row .col:nth-child(4) p {
    font-size: 16px;
  }
  .footerTop .row .col ul li {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1200px) {
  .footerTop .row {
    flex-wrap: wrap;
  }
  .footerTop .row .col:nth-child(5) {
    width: 100%;
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media only screen and (max-width: 992px) {
  .footerTop .row .col:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 32px;
  }
  .footerTop .row .col:nth-child(1) p {
    text-align: center;
  }
  .footerTop .row .col:nth-child(2),
  .footerTop .row .col:nth-child(3),
  .footerTop .row .col:nth-child(4) {
    width: 32%;
  }
}
@media only screen and (max-width: 769px) {
  .footerTop .row .col:nth-child(4) {
    width: 100%;
    margin-top: 35px;
  }
  .footerTop .row .col:nth-child(2),
  .footerTop .row .col:nth-child(3) {
    width: 50%;
  }
}
@media only screen and (max-width: 569px) {
  .footerTop .row .col .logo img {
    width: 30px;
  }
  .footerTop .row .col:nth-child(1) p,
  .footerTop .row .col:nth-child(4) p {
    font-size: 16px;
  }
  .footerTop .row .col ul li {
    font-size: 16px;
  }
  .footerTop {
    padding: 24px 16px;
  }
}
