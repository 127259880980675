.editProfileTop .headerContent {
  display: flex;
  align-items: center;
}

.editProfileTop .headerContent {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}

.editProfileTop .headerContent .CaretLeft {
  display: none;
  cursor: pointer;
}

.editProfileTop .headerContent img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.editProfileTop .headerContent h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.editProfileTop .editRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.editProfileTop .editRow p {
  text-align: center;
  padding: 0 25px;
}

.editProfileTop .editRow .profile {
  margin-top: 25px;
  width: 220px;
  height: 220px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.editProfileTop .editRow .profile p {
  text-align: center;
  width: 100%;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
  text-align: center;
}

.editProfileTop .editRow .profile .uploadBtn {
  position: relative;
  overflow: hidden;
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 8px;
  cursor: pointer;
  background: #fff;
  margin: 0 auto 15px;
}

.editProfileTop .editRow .profile .uploadBtn img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.editProfileTop .editRow .profile .uploadBtn .btn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}

.editProfileTop .editRow .profile .uploadBtn input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 180px;
  height: 180px;
}

.editProfileTop .editRow .inputData {
  width: 100%;
  max-width: 300px;
}

.editProfileTop .editRow .inputData form {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  gap: 34px;
}

.editProfileTop .editRow .inputData form .name,
.editProfileTop .editRow .inputData form .pass {
  width: 100%;
}

.editProfileTop .editRow .inputData form .btn {
  width: 23%;
  display: flex;
  justify-content: flex-end;
}

.editProfileTop .editRow .inputData form .name p,
.editProfileTop .editRow .inputData form .pass p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 16px;
}

.editProfileTop .editRow .inputData form .name div,
.editProfileTop .editRow .inputData form .pass .passInner {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  display: block;
  outline: none;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  position: relative;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 1px;
}

.editProfileTop .editRow .inputData form .name div:hover,
.editProfileTop .editRow .inputData form .pass .passInner:hover {
  background-image: linear-gradient(to right, rgba(64, 64, 85, 0.45) 0%, rgba(19, 157, 224, 0.45) 100%);
}

.editProfileTop .editRow .inputData form .name div:focus-within,
.editProfileTop .editRow .inputData form .pass .passInner:focus-within {
  background-image: linear-gradient(to right, rgba(64, 64, 85, 0.45) 0%, rgba(19, 157, 224, 0.45) 100%);
}

.editProfileTop .editRow .inputData form .name div span,
.editProfileTop .editRow .inputData form .pass .passInner span {
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 100%;
  border: none;
  width: 100%;
}

.editProfileTop .editRow .inputData form .name div span:hover,
.editProfileTop .editRow .inputData form .pass .passInner span:hover {
  background: #393636;
}

.editProfileTop .editRow .inputData form .name div span:focus-within,
.editProfileTop .editRow .inputData form .pass .passInner span:focus-within {
  background: #393636;
}

.editProfileTop .editRow .inputData form .name div span input,
.editProfileTop .editRow .inputData form .pass .passInner span input {
  width: 100%;
  background-color: transparent !important;
  top: 0px;
  left: 0px;
  padding: 0px 16px;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #bcbcbc;
}

.editProfileTop .editRow .inputData form .pass .passInner span input {
  padding-right: 55px;
}

.editProfileTop .editRow .inputData form .pass .passInner span div button {
  position: absolute;
  right: 16px;
  top: 9px;
  background: transparent;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  border: none;
}

.editProfileTop .editRow .inputData form .btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.editProfileTop .editRow .inputData form .btn p {
  margin-bottom: 16px;
}

.editProfileTop .editRow .inputData form .btn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  max-width: 340px;
  width: 100%;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #f5f5f5;
}

.editProfileTop .editRow .inputData form .btn button:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #404055 0%, #139de0 100%);
}

.editProfileTop .editRow .inputData form .btn button:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(90deg, #404055 0%, #139de0 100%);
}

@media only screen and (max-width: 1100px) {
  .editProfileTop .headerContent .CaretLeft {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .editProfileTop .editRow {
    flex-wrap: wrap;
  }

  .editProfileTop .editRow .profile {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 869px) {
  .editProfileTop .editRow .inputData form {
    flex-wrap: wrap;
  }

  .editProfileTop .editRow .inputData form .btn {
    max-width: 340px;
    width: 100%;
  }

  .editProfileTop .headerContent {
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .editProfileTop .headerContent h1 {
    font-size: 24px;
  }

  .editProfileTop .headerContent img {
    width: 24px;
  }

  .editProfileTop .headerContent .CaretLeft {
    position: absolute;
    left: 0px;
  }
}

@media only screen and (max-width: 669px) {
  .editProfileTop .editRow .inputData form .name,
  .editProfileTop .editRow .inputData form .pass {
    margin-bottom: 20px;
  }

  .editProfileTop .editRow .inputData form .btn {
    height: 47px;
  }
}
