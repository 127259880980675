@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");

body {
	background: linear-gradient(180deg, #151515 0%, #000000 100%) !important;
}

.ProfileMain .bg {
	background-image: url("./../../img/profileBg.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
}

.ProfileMain .bg .rowProfile {
	width: 90%;
	margin: auto;
	padding-bottom: 100px;
	display: flex;
	overflow: hidden;
}

.ProfileMain .bg .rowProfile .tablist {
	gap: 16px;
	max-width: 400px;
	width: 100%;
	height: auto;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 20px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin-right: 20px;
	align-self: flex-start;
}

.ProfileMain .bg .rowProfile .tablist .headerTablist {
	background: rgba(255, 255, 255, 0.1);
	border-radius: 20px 20px 0px 0px;
	width: 100%;
	padding: 24px 32px;
}

.ProfileMain .bg .rowProfile .tablist .headerTablist .userProfile {
	display: flex;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 16px;
}

.ProfileMain .bg .rowProfile .tablist .headerTablist .userProfile span {
	text-align: center;
}

.ProfileMain .bg .rowProfile .tablist .headerTablist .userProfile .userImg {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 20px;
	margin-bottom: 15px;
}

.ProfileMain .bg .rowProfile .tablist .headerTablist .userProfile span h3,
.ProfileMain .bg .rowProfile .tablist .headerTablist h3,
.ProfileMain .bg .rowProfile .tablist .tablistmain h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 130%;
	color: #ffffff;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	font-family: "Plus Jakarta Sans", sans-serif;
	margin-bottom: 4px;
}

.ProfileMain .bg .rowProfile .tablist .headerTablist .userProfile span p,
.ProfileMain .bg .rowProfile .tablist .headerTablist {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	font-family: "Plus Jakarta Sans", sans-serif;
	color: #ffffff;
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain {
	padding: 24px 32px;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain h3 {
	margin-bottom: 15px !important;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul .last {
	margin-bottom: 0px;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul a {
	display: inline-block;
	opacity: 0.6;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul a:hover {
	opacity: 1;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul a.unselected {
	opacity: 1;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul li {
	font-family: "Plus Jakarta Sans", sans-serif;
	list-style-type: none;
	display: flex;
	align-items: center;
	/* opacity: 0.6; */
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: #ffffff;
	flex: none;
	cursor: pointer;
	order: 1;
	flex-grow: 1;
	margin-bottom: 17.5px;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul li:hover {
	opacity: 1;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul li img {
	margin-right: 12px;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain .account,
.ProfileMain .bg .rowProfile .tablist .tablistmain .support {
	margin-top: 25.5px;
}

.ProfileMain .bg .rowProfile .tablist .tablistmain ul .logout {
	margin-top: 30px;
	margin-bottom: 0px;
}

.ProfileMain .bg .rowProfile .mainContent {
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	color: #ffffff;
	font-family: "Plus Jakarta Sans", sans-serif;
}

@media only screen and (max-width: 1500px) {
	.ProfileMain .bg .rowProfile .tablist {
		max-width: 350px;
	}
}

@media only screen and (max-width: 1100px) {
	.ProfileMain .bg .rowProfile .tablist {
		margin-left: -350px;
		margin-right: 0px;
		transition: 0.2s;
	}

	.ProfileMain .bg .rowProfile .tablistActive {
		margin-left: 0px !important;
		margin-right: 20px;
		width: 100%;
	}

	.ProfileMain .bg .rowProfile .mainContent {
		width: 100%;
	}

	.ProfileMain .bg .rowProfile .mainContentAtive {
		margin-left: 0px;
	}
}

@media only screen and (max-width: 769px) {
	.ProfileMain .bg .rowProfile .mainContentAtive {
		margin-left: 100%;
		display: none;
	}
}

@media only screen and (max-width: 400px) {
	.ProfileMain .bg .rowProfile .tablist {
		width: 100%;
	}

	.ProfileMain .bg .rowProfile .tablist {
		margin-left: -100%;
		margin-right: 0px;
	}

	.ProfileMain .bg .rowProfile .tablistActive {
		margin-left: 0% !important;
	}
}
