@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;700&display=swap");
.faqsTop {
  width: 100%;
  max-width: 820px;
  position: relative;
}
.faqsTop .headerContent {
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.faqsTop .headerContent .contactInner {
  display: flex;
  align-items: center;
  position: relative;
}

.faqsTop .headerFaqs .caretLeft {
  display: none;
}
.faqsTop .termHeader {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}
.faqsTop .headerContent .contactInner img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.faqsTop .headerContent .contactInner h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #ffffff;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.faqsTop .headerContent button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 45px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 12px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.faqsTop .headerContent button span {
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
}
.faqsTop .headerContent button span img {
  margin-right: 11.87px;
}

.faqsTop textarea {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  resize: none;
  padding: 16px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  box-shadow: none;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #bcbcbc;
  margin-bottom: 20px;
}
.faqsTop textarea:hover {
  background: rgba(255, 255, 255, 0.1);
}
.faqsTop textarea:focus {
  background: rgba(255, 255, 255, 0.05);
}
.faqsTop .sendMessage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 260px;
  height: 45px;
  background: linear-gradient(90deg, #404055 0%, #139de0 100%, #139de0 100%);
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 16px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #f5f5f5;
  margin-bottom: 100px;
}
.faqsTop .sendMessage:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}
.faqsTop .sendMessage:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(90deg, #404055 0%, #139de0 100%);
}

.faqsTop .headerFaqs .row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.faqsTop .headerFaqs .row h1 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  margin-left: 12px;
  color: #fff;
}
.faqsTop .headerFaqs .row img {
  width: 40px;
  height: 40px;
}
.faqsTop .headerFaqs p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1100px) {
  .faqsTop .headerContent .contactInner {
    position: relative;
  }
  .faqsTop .headerFaqs .caretLeft {
    position: absolute;
    display: block;
    left: 0px !important;
    cursor: pointer;
  }
  .faqsTop .headerContent .contactInner h1 {
    font-size: 24px;
  }
  .faqsTop .headerContent .contactInner img {
    width: 24px;
  }
  .faqsTop .headerFaqs .row {
    justify-content: center;
  }
  .faqsTop .headerFaqs .row img {
    width: 24px;
  }
  .faqsTop .headerFaqs .row h1 {
    font-size: 24px;
  }
  .faqsTop .headerFaqs .row p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 669px) {
  .faqsTop .headerContent {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .faqsTop .headerContent {
    margin-bottom: 24px !important;
  }
  .faqsTop .headerContent .contactInner {
    width: 100%;
    justify-content: center;
  }
  .faqsTop .headerContent button {
    margin-top: 24px;
    max-width: 380px;
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .faqsTop textarea {
    height: 200px;
  }
  .faqsTop .sendMessage {
    width: 100%;
  }
}
