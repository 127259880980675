.share {
  width: 100%;
}

.share h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
}

.share .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.share .row div {
  width: 176px;
  height: 48px;
  margin: 0px 20px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgba(64, 64, 85, 0.45) 0%,
    rgba(19, 157, 224, 0.45) 100%
  );
  padding: 1px;
}

.share .row div span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}
.share .row div span p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  margin-left: 20px;
}

.share .row div span {
  background: #393636;
  border-radius: 10px;
}

@media only screen and (max-width: 1100px) {
  .share .row div {
    width: auto;
    border-radius: 10px;
  }
  .share .row div span {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 8px 24px;
  }
  .share .row div span p {
    display: none;
  }
  .share .row {
    margin-bottom: 48px;
  }
}
@media only screen and (max-width: 769px) {
  .share .row div span img {
    width: 24px;
  }
  .share .row div {
    margin: 0px 10px;
  }
  .share .row div span {
    padding: 8px 16px;
  }
}
@media only screen and (max-width: 569px) {
  .share .row div span {
    padding: 8px 10px;
  }
}
