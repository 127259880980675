@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.CreateTop {
    background: linear-gradient(180deg, #151515 0%, #000000 100%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CreateTop .row {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    background-image: url("./../../img/BG.svg");
    background-position: 100%;
    background-size: cover;
}

.CreateTop .left, .CreateTop .right {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.CreateTop .left {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(35px);
    border-radius: 0px 60px 60px 0px;
    flex-direction: column;
    padding: 30px;
    gap: 30px;
}

.CreateTop .left .logo {
    display: flex;
    align-items: center;
}

.CreateTop .left .logo img {
    width: 30px;
    height: 30px;
}

.CreateTop .left .logo h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    line-height: 17px;
    text-transform: uppercase;
    background: linear-gradient(90deg, #a6c9f1 0%, #139de0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-left: 8px;
}

.CreateTop .right {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.7));
    overflow: hidden;
}

.CreateTop .right .image-grid {
    display: flex;
    gap: 10px;
    position: relative;
    height: 100%;
    width: 100%;
}

.CreateTop .right .image-grid .column {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.CreateTop .right .image-grid .column img {
    width: 100%;
    height: auto;
    display: block;
}

/* Orta sütun yukarı doğru kayıyor */
@keyframes scroll-center {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50%);
    }
}

/* Sol sütun aşağı doğru kayıyor */
@keyframes scroll-left {
    0% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(0);
    }
}

/* Sağ sütun aşağı doğru kayıyor */
@keyframes scroll-right {
    0% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(0);
    }
}

/* Animasyonların uygulanması */
.CreateTop .right .image-grid .left-column {
    animation: scroll-left 180s linear infinite;
    opacity: 0.5;
}

.CreateTop .right .image-grid .center-column {
    animation: scroll-center 180s linear infinite;
    opacity: 0.5;
}

.CreateTop .right .image-grid .right-column {
    animation: scroll-right 180s linear infinite;
    opacity: 0.5;
}

@media only screen and (max-width: 992px) {
    .CreateTop .row {
        flex-wrap: wrap;
    }

    .CreateTop .left {
        width: 100%;
        border-radius: 0px !important;
        backdrop-filter: blur(0px);
    }

    .CreateTop .right {
        display: none;
    }

    .CreateTop .left .logo {
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
